// eslint-disable-next-line
import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Input from "@material-ui/core/Input";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
// import Collapse from "@material-ui/core/Collapse";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import {
  create_payment_method,
  get_payment_method,
  clear_error,
  create_payment_method_first,
  clear_state_payment,
} from "../actions/form";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../assets/home_load.gif";

import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import discover from "payment-icons/min/flat/discover.svg";
import diners from "payment-icons/min/flat/diners.svg";
import jcb from "payment-icons/min/flat/jcb.svg";
import unionpay from "payment-icons/min/flat/unionpay.svg";
import NumberFormat from "react-number-format";

import * as Scroll from "react-scroll";
var scroll = Scroll.animateScroll;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: "10px 5px 5px 10px",
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down("768")]: {
      padding: "0",
    },
  },

  paper: {
    width: "100%",
    background: "white",
    minHeight: "150px",
    borderRadius: "10px",
    overflow: "hidden",
    [theme.breakpoints.down("768")]: {
      maxWidth: "375px",
      margin: "0 ",
      padding: "15px ",
    },
  },

  margin: {
    padding: theme.spacing(2),
  },
  inputSmall: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSmall_: {
    fontSize: "13px",
    width: "100%",
    height: "30px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formHeader: {
    margin: theme.spacing(2, "auto", 4),
    textAlign: "center",
  },
  formLabel: {
    fontSize: "13px",
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  formGroupProfileSection: {
    marginTop: theme.spacing(2),
  },
  svg_image: {
    height: "35px",
    width: "35px",
    // paddingTop: theme.spacing(2.5),
    [theme.breakpoints.down("680")]: {
      paddingTop: 0,
      paddingBottom: "20px",
    },
  },
  equalize: {
    paddingTop: theme.spacing(2.5),
  },
  button: {
    marginTop: "5px",
    alignItems: "center",
    color: "#FF3D00",
    justifyContent: "center",
    backgroundColor: "#fff!important",
    border: "1.5px solid #FF3D00",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#F0FFF0!important",
    },
  },
  button_: {
    alignItems: "center",
    color: "#FF3D00",
    float: "left",
    marginLeft: '10px',
    justifyContent: "center",
    backgroundColor: "#fff!important",
    border: "1.5px solid #FF3D00",
    borderRadius: "5px",
    [theme.breakpoints.down("550px")]: {
      margin: "20px auto",

    },
  },
  buttonForm: {
    marginTop: "20px",
    marginBottom: "38px",
    display: "block",
    width: "100%",
    padding: "10.5px 0",
    background: "#fff",
    borderRadius: "10px",
    fontFamily: "Dosis",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#FFFFFF",
    margin: "0 auto",
    outline: "none",
    cursor: "pointer",
    border: "1px solid #BDBDBD",
    textAlign: "center",
    letterSpacing: "0.15px",
    transition: "background 0.4s",
    [theme.breakpoints.down("680")]: {
      marginTop: "20px",
      marginBottom: "40px",
    },
    "&:hover": {
      backgroundColor: "#cce5e7",
    },
  },
}));

function PaymentMethods(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const payment_methods = props?.payment_methods;

  const [card_number, setCard_number] = React.useState("");
  const [exp_month, setExp_month] = React.useState("");
  const [exp_year, setExp_year] = React.useState("");
  const [cvc, setCvc] = React.useState("");
  const [disable, setDisable] = React.useState(true);

  const [f_name, setF_name] = React.useState("");
  const [l_name, setL_name] = React.useState("");
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(true);
  // eslint-disable-next-line
  const [save_card, setSavecard] = React.useState(false);

  const [bool] = React.useState(true);
  // eslint-disable-next-line
  const [reload_page, setReload_page] = React.useState(true);

  const saving_details = async () => {
    setSavecard(true);

    // Removing functionality of making the first card automatically primary
    // =====================================================================
    if (props?.payment_methods?.length === 0) {
      console.log("Creating first card")
      await props.create_payment_method_first(card_number, exp_month, exp_year, cvc, f_name, l_name);
      props.clear_state_payment();
      clearState();

    } else {
      console.log("Creating another card")
      await props.create_payment_method(card_number, exp_month, exp_year, cvc, f_name, l_name);
      clearState();
      setDisable(false);
    }
    setDisable(true);

    // if (reload_page === true) {
    //   setTimeout(() => {
    //     window.location.href = "/petprovider";
    //   }, 5000);
    // }
  };

  const clearState = () => {
    setCard_number("");
    setCvc("");
    setExp_month("");
    setExp_year("");
    setF_name("");
    setL_name("");
  };

  React.useEffect(() => {
    var stage = 0;
    var stage_ = parseInt(stage);
    const id = localStorage.getItem("logged_in");
    if (id !== null && id !== undefined) {
      localStorage.setItem(`booking_status_${id}`, stage_);
    }

    const test = payment_methods?.filter(
      (payment) => payment.is_primary === bool
    );
    if (test.length >= 1) {
      setReload_page(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props?.payment_methods?.length > 0) {
      const cards = true;
      localStorage.setItem(`payment_methods_${props.user.id}`, cards);
    } else {
      const cards = false;
      localStorage.setItem(`payment_methods_${props.user.id}`, cards);
    }
    const check = payment_methods?.filter(
      (payment) => payment.is_primary === bool
    );
    if (check.length !== 1) {
      const no_primary_card = true;
      localStorage.setItem(`no_primary_card_${props.user.id}`, no_primary_card);
    } else {
      const no_primary_card = false;
      localStorage.setItem(`no_primary_card_${props.user.id}`, no_primary_card);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payment_methods]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (l_name && f_name && card_number && exp_year && exp_month && cvc) {
      setDisable(false);
    }
  }, [l_name, f_name, card_number, exp_year, exp_month, cvc]);

  let alert;
  if (props.errors.msg.non_field_errors) {
    let msg = props.errors.msg.non_field_errors;
    alert = <div className="alerts">{msg}</div>;
  }
  if (props.errors.msg.length >= 1) {
    let msg = props.errors.msg;
    alert = <div className="alerts">{msg}</div>;
  }

  const [show_form, setShow_form] = React.useState(false);
  const set_show_form = () => {
    setShow_form(!show_form);
    scroll.scrollToTop();
  };

  React.useEffect(() => {
    if (props.services?.card_created) {
      setShow_form(false);
      scroll.scrollToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.services?.card_created]);
  
  return (
    <div>
      {props.services?.card_created ? (
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShow_form(false);
                    props.clear_error();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <div className={classes.message}>{"Card added successfully."}</div>
            </Alert>

        </Grid>
      ) : null}
      {show_form === true ? (
        <Paper elevation={0}>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Credit/Debit Card"
              >
                <Tab label="Payment Information" {...a11yProps(0)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {/* Code to connect to Stripe goes here */}
              {props.errors.msg.non_field_errors ? (
                  <Grid item xs={12}>
                      <Alert
                        severity="error"
                        style={{ marginBottom: '10px' }}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                              props.clear_error();
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <div className={classes.message}>{alert}</div>
                      </Alert>
                  </Grid>
                ) : null}
              {props.errors.msg.length >= 1 ? (
                  <Grid item xs={12}>
                    <Alert
                      severity="error"
                      style={{ marginBottom: '10px' }}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            props.clear_error();
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <div className={classes.message}>{alert}</div>
                    </Alert>
                  </Grid>
                ) : null}
              <Grid container spacing={1}>
                <Grid item md={6} xs={12} sm={6}>
                  <FormLabel component="label" className={classes.formLabel}>
                    First Name
                  </FormLabel>
                  <Input
                    placeholder="First name"
                    fullWidth
                    disableUnderline
                    className={classes.inputSmall}
                    value={f_name}
                    onChange={(e) => setF_name(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="label" className={classes.formLabel}>
                    Last Name
                  </FormLabel>
                  <Input
                    placeholder="Last name"
                    fullWidth
                    disableUnderline
                    value={l_name}
                    className={classes.inputSmall}
                    onChange={(e) => setL_name(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <Grid sm={false} container spacing={1} alignItems="flex-end">
                    <Grid item xs={12}>
                      <FormLabel
                        component="label"
                        className={classes.formLabel}
                      >
                        Card number
                      </FormLabel>{" "}
                      <br />
                    </Grid>
                    <Grid item>
                      <img
                        src={visa}
                        className={classes.svg_image}
                        alt="Logo"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={mastercard}
                        className={classes.svg_image}
                        alt="Logo"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={discover}
                        className={classes.svg_image}
                        alt="Logo"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={diners}
                        className={classes.svg_image}
                        alt="Logo"
                      />
                    </Grid>
                    <Grid item>
                      <img src={jcb} className={classes.svg_image} alt="Logo" />
                    </Grid>
                    <Grid item>
                      <img
                        src={unionpay}
                        className={classes.svg_image}
                        alt="Logo"
                      />
                    </Grid>
                  </Grid>
                  <NumberFormat
                    format="#### #### #### ####"
                    placeholder="4444 5555 6666 7777"
                    customInput={Input}
                    value={card_number}
                    disableUnderline
                    style={{ width: "100%" }}
                    className={classes.inputSmall}
                    onChange={(e) => setCard_number(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/*<div  className={classes.equalize} ></div>*/}
                    <FormLabel component="label" className={classes.formLabel}>
                      Expiry month
                    </FormLabel>
                    <Select
                      onChange={(e) => setExp_month(e.target.value)}
                      disableUnderline
                      displayEmpty
                      fullWidth
                      value={exp_month}
                      className={classes.inputSmall}
                      inputProps={{
                        "aria-label": "Select Expiry month",
                      }}
                    >
                      <MenuItem value="01">01</MenuItem>
                      <MenuItem value="02">02</MenuItem>
                      <MenuItem value="03">03</MenuItem>
                      <MenuItem value="04">04</MenuItem>
                      <MenuItem value="05">05</MenuItem>
                      <MenuItem value="06">06</MenuItem>
                      <MenuItem value="07">07</MenuItem>
                      <MenuItem value="08">08</MenuItem>
                      <MenuItem value="09">09</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                    </Select>
                  </MuiPickersUtilsProvider>
                </Grid>

                {/*<Grid item  sm={6}> 
                        <FormLabel component="label" className={classes.formLabel}>CVC</FormLabel>                 
                        <Input
                          placeholder="CVC"
                          fullWidth
                          disableUnderline
                          className={classes.inputSmall}
                          value={cvc}
                          fullWidth
                          onChange={(e) => setCvc(e.target.value)}
                          startAdornment={
                            <InputAdornment position="start">
                              <LockOpenIcon />
                            </InputAdornment>
                          }
                        />
                      </Grid>*/}

                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Expiry year
                    </FormLabel>
                    <Select
                      onChange={(e) => setExp_year(e.target.value)}
                      disableUnderline
                      displayEmpty
                      fullWidth
                      value={exp_year}
                      className={classes.inputSmall}
                      inputProps={{
                        "aria-label": "Select Expiry year",
                      }}
                    >
                      <MenuItem value="21">2021</MenuItem>
                      <MenuItem value="22">2022</MenuItem>
                      <MenuItem value="23">2023</MenuItem>
                      <MenuItem value="24">2024</MenuItem>
                      <MenuItem value="25">2025</MenuItem>
                      <MenuItem value="26">2026</MenuItem>
                      <MenuItem value="27">2027</MenuItem>
                      <MenuItem value="28">2028</MenuItem>
                      <MenuItem value="29">2029</MenuItem>
                      <MenuItem value="30">2030</MenuItem>
                      <MenuItem value="31">2031</MenuItem>
                    </Select>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormLabel component="label" className={classes.formLabel}>
                    CVC
                  </FormLabel>{" "}
                  <br />
                  <NumberFormat
                    format="###"
                    placeholder="CVC"
                    customInput={Input}
                    value={cvc}
                    disableUnderline
                    style={{ width: "100%" }}
                    className={classes.inputSmall}
                    onChange={(e) => setCvc(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <br />
                  <Button
                    variant="contained"
                    disabled={disable}
                    color="secondary"
                    style={{
                      maxWidth: "170px",
                      maxHeight: "35px",
                      minWidth: "170px",
                      minHeight: "35px",
                    }}
                    className={classes.button}
                    startIcon={<AddCircleRoundedIcon />}
                    onClick={saving_details}
                  >
                    {"Add card"}
                    {props.form.create_card_loading === true ? (
                      <div
                        style={{
                          marginLeft: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <img src={Spinner} alt="" height="25px" width="25px" />
                      </div>
                    ) : null}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "35px",
                      minWidth: "100px",
                      minHeight: "35px",
                      float: "right",
                    }}
                    onClick={set_show_form}
                    className={classes.button}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </Paper>
      ) : (
        <div className={classes.paper}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              maxWidth: "130px",
              maxHeight: "35px",
              minWidth: "130px",
              minHeight: "35px",
            }}
            onClick={set_show_form}
            className={classes.button_}
          >
            {"Add new +"}
          </Button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  services: state.services,
  user: state.auth.user,
  messages: state.messages,
  errors: state.errors,
  all_services: state.services.admin_services,
});

export default connect(mapStateToProps, {
  create_payment_method,
  get_payment_method,
  clear_error,
  create_payment_method_first,
  clear_state_payment,
})(PaymentMethods);
