import {
  FORM_LOADED,
  FORM_LOADING,
  FORM_LOADING_DATA,
  FORM_LOADED_DATA,
  SENDING_DATA,
  GET_AVAILABILITY,
  BACKGROUNDCHECK_PAYMENT,
  PAYMENT_SCREENING,
  CLEAR_AVAILABILITY,
  CREATE_AVAILABILITY_INSTANCE,
  SAVE_COLLECTIVE_DATA,
  SENT_CERTIFICATE,
  CERTIFICATE_LOADED,
  GO_TO_NEXT,
  RESET_GO_TO_NEXT,
  USER_EDITING,
  RESET_USER_EDITING,
  RESET_SAVE_SIGNAL,
  GET_AVAILABILITY_DATA,
  PHOTOS_LOADED,
  PHOTO_LOADING,
  PHOTO_UPLOADED,
  PHOTOS_DELETED,
  CLEAR_AVAILABLE,
  FORM_LOADING_CREATE,
  FAQS_LOADING,
  ENABLE_BUTTON,
  FAQS_LOADED,
  BOOKING_RELOAD_PAGE,
  MAKE_BOOKING_FIRST_SIGNAL,
  MAKE_BOOKING_FIRST_SIGNAL_RESET,
  FORM_PET_UPDATE_LOADING,
  PROVIDER_IMAGES_LOADING,
  PROVIDER_IMAGES_FETECHED,
  PROVIDER_IMAGES_FAILED,
  DELETE_PHOTO_LOADING,
  USER_MISSING_INFO,
  USER_MISSING_INFO_TURN_OFF,
  SET_TO_NULL_NEXT_APPOINTMENTS,
  MAKE_PRIMARY_LOADING,
  MAKE_PRIMARY,
  MAKE_PRIMARY_FAILED

} from '../actions/types';

const initialState = {
  isLoading: false,
  loading_collective: false,
  fetch: false,
  availability:[],
  availability_data: null,
  payment: null,
  screening: null,
  refresh: false,
  signal_save: false,
  go_to_next: false,
  user_editing: false,
  photos: null,
  photos_loading: false,
  fetch_photos: false,
  photo_deleted: false,
  create_card_loading: false,
  enable_form: false,
  faqs_loading: false,
  booking_confirm_page: false,
  faqs: null,
  make_booking_first: false,
  pet_updating: false,
  provider_images: null,
  provider_images_loading: false,
  provider_images_failed: false,
  user_missing_info: false,
  
  availability_next: false,
  availability_data_next: false,
  set_view_appointments_ready: false,

  making_primary_loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MAKE_PRIMARY_LOADING:
      return {
        ...state,
        making_primary_loading: true
      };
    case MAKE_PRIMARY:
      return {
        ...state,
        making_primary_loading: false
      };
    case MAKE_PRIMARY_FAILED:
      return {
        ...state,
        making_primary_loading: false
      };
    case GET_AVAILABILITY:
      const existingDataIds = new Set(state.availability?.map(i => i.id));
      const uniqueResultsAvailability = action.payload?.results?.filter(result => !existingDataIds.has(result.id));
      // console.log(action.payload.next)
      return {
        ...state,
        availability: [...state.availability, ...uniqueResultsAvailability],
        availability_next: action.payload.next,
      };

    case GET_AVAILABILITY_DATA:
      let currentState = state.availability_data
      if(currentState === null){
        currentState = []
      }else{
        currentState = state.availability_data
      }
      const existingAvailDataIds = new Set(currentState?.map(i => i.id));
      const uniqueResultsAvailabilityData = action.payload?.results?.filter(result => !existingAvailDataIds.has(result.id));
      return {
        ...state,
        availability_data: [...currentState, ...uniqueResultsAvailabilityData],
        availability_data_next: action.payload.next,
      };

    case SET_TO_NULL_NEXT_APPOINTMENTS:
      console.log('state.set_view_appointments_ready')
      // console.log(state.set_view_appointments_ready)
      return {
        ...state,
         availability_next: null,
         availability_data_next: null,
         set_view_appointments_ready: true
      };

    case PROVIDER_IMAGES_LOADING:
      return {
        ...state,
        provider_images_loading: true
      };
    case PROVIDER_IMAGES_FETECHED:
      return {
        ...state,
         provider_images_loading: false,
         provider_images_failed: false,
         provider_images: action.payload
      };
    case PROVIDER_IMAGES_FAILED:
      return {
        ...state,
        reload_certificates: false,
        provider_images_loading: false,
      };
    case CERTIFICATE_LOADED:
      return {
        ...state,
        reload_certificates: false
      };
    case SENT_CERTIFICATE:
      return {
        ...state,
        reload_certificates: true
      };
    case FORM_LOADING_CREATE:
      return {
        ...state,
        create_card_loading: true
      };
    case FORM_PET_UPDATE_LOADING:
      return {
        ...state,
        pet_updating: true
      };
    case MAKE_BOOKING_FIRST_SIGNAL:
      return {
        ...state,
        make_booking_first: true
      };
    case MAKE_BOOKING_FIRST_SIGNAL_RESET:
      return {
        ...state,
        make_booking_first: false
      };
    case BOOKING_RELOAD_PAGE:
      return {
        ...state,
        booking_confirm_page: true
      };
    case SENDING_DATA:
      return {
        ...state,
        fetch: true
      };
    case FAQS_LOADING:
      return {
        ...state,
        faqs_loading: true
      };
    case ENABLE_BUTTON:
      return {
        ...state,
        enable_form: true
      };
    case FAQS_LOADED:
      return {
        ...state,
        faqs_loading: false,
        faqs: action.payload,
      };
    case PHOTO_LOADING:
      return {
        ...state,
        photos_loading: true
      };
    case PHOTO_UPLOADED:
      return {
        ...state,
        photos_loading: false,
        fetch_photos: true
      };
    case PHOTOS_DELETED:
      return {
        ...state,
        photo_deleted: true,
        photo_delete_loading: false,
        fetch_photos: true
      };
    case DELETE_PHOTO_LOADING:
      return {
        ...state,
        photo_delete_loading: true,
        fetch_photos: true
      };
    case PHOTOS_LOADED:
      return {
        ...state,
        photos: action.payload,
        photos_loading: false,
        photo_deleted: false,
        fetch_photos: false
      };
    case USER_EDITING:
      return {
        ...state,
        user_editing: true
      };
    case RESET_USER_EDITING:
      return {
        ...state,
        user_editing: false
      };
    case RESET_GO_TO_NEXT:
      return {
        ...state,
        go_to_next: false
      };
    case GO_TO_NEXT:
      return {
        ...state,
        go_to_next: true,
        user_editing: false,
        user_missing_info: false
      };
    case SAVE_COLLECTIVE_DATA:
      return {
        ...state,
        signal_save: true
      };
    case RESET_SAVE_SIGNAL:
      return {
        ...state,
        signal_save: false
      };
    case USER_MISSING_INFO:
      return {
        ...state,
        user_missing_info: true
      };
    case USER_MISSING_INFO_TURN_OFF:
      return {
        ...state,
        user_missing_info: false
      };
    case CREATE_AVAILABILITY_INSTANCE:
      return {
        ...state,
        refresh: true
      };
    case CLEAR_AVAILABILITY:
      return {
        ...state,
        availability: null
      };
    case BACKGROUNDCHECK_PAYMENT:
      return {
        ...state,
        payment: action.payload
      };
    case PAYMENT_SCREENING:
      return {
        ...state,
        screening: action.payload
      };
    case FORM_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case FORM_LOADING_DATA:
      return {
        ...state,
        loading_collective: true
      };
    case CLEAR_AVAILABLE:
      return {
        ...state,
        availability_data: null
      };
    case FORM_LOADED:
      return {
        ...state,
        isLoading: false,
        fetch: false,
        create_card_loading: false,
        pet_updating: false
      };
    case FORM_LOADED_DATA:
      return {
        ...state,
        loading_collective: false,
        signal_save: false,
        user_editing: false,
        fetch: false
      };
    default:
      return {
        ...state
      };
  }
}
