/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BioSection from '../components/DashboardProfilePicSection';
import AddressSection from '../components/DashboardAddressSection';
import ContactSection from '../components/DashboardContactSection';
import ChangePassword from '../components/ProfileChangePassword';
import { loadUser } from '../actions/auth.js';

import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

function ResponsiveDrawer(props) {
  const { user } = props;

  React.useEffect(() => {
    if (props.form.fetch === true) {
      props.loadUser();
      props.loadUser();
      console.log('Reloading...');
    }
  }, [props.form.fetch]);

  React.useEffect(() => {
    scroll.scrollToTop();
  }, []);

  // function sendReq(data, user_id) {
  //   const timer = setTimeout(() => {
  //     dispatch(sendUserData(data, user_id));
  //   }, 2000);

  //   return timer;
  // }


  return (
    <div>
      <BioSection
          firstName={user.first_name}
          lastName={user.last_name}
          tagline={user.tagline}
          bio={user.bio}
          dob={user.date_of_birth}
      />
      <AddressSection
          address_line_1={user.address_line_1}
          address_line_2={user.address_line_2}
          city={user.city}
          state={user.state}
          country={user.country}
          zipcode={user.zipcode}
      />
      <ContactSection
          phone_number={user.phone_number}
          alternate_phone_number={user.alternate_phone_number}
          email={user.email}
          emergency_contact_name={user.emergency_contact_name}
          emergency_contact_phone_number={user.emergency_contact_phone_number}
          emergency_contact_email={user.emergency_contact_email}
      />
      {!user.is_social_user ? <ChangePassword /> : ''}
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.forms,
  user: state.auth.user
});

export default connect(mapStateToProps, { loadUser })(ResponsiveDrawer);
