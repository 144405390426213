import React, { Component, Fragment } from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import store from "./store";
import AuthenticationRoute from "./views/AuthenticationRoute.js";
import HomePage from "./views/HomePage.js";
import ResultsPage from "./views/ResultsPage.js";
import SinglePetProviderView from "./views/SinglePetServiceProviderView";
import LoginPage from "./views/LoginPage";
import SignUpPage from "./views/SignUpPage";
import SignUpGoogle from "./views/SignUpGoogle";
import RenderDashboard from "./views/RenderDashboard";
import ErrorsPage from "./views/ErrorsPage";
import VerifySignupCode from "./views/VerifySignupCode";
import VerifySignup from "./views/VerifySignup";
import ForgotPassword from "./views/ForgotPassword";
import Verifyemailcode from "./views/Verifyemailcode";
import verifyemailreset from "./views/verifyemailreset";
import ResetPassward from "./views/ResetPassward";
import Payments from "./views/Payments";
import SelectAccount from "./views/SelectAccount";
import ContactPetProvider from "./views/ContactPetProvider";
import RenderWizard from "./views/RenderWizard";
import RenderBookingWizard from "./views/RenderBookingWizard";
import AboutUs from "./views/AboutUs";
import FQAs from "./views/FQAs";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";
import Filter from "./views/Filter";
import ProfileInfo from "./views/ProfileInfo";
import Tips from "./views/Tips";
import CancellationPolicy from "./views/CancellationPolicy";
import BookingDetails from "./views/BookingDetails";
import ProviderGallery from "./components/ProviderGallery";
import { browserName, isMobile } from 'react-device-detect';
import { loadUser } from "./actions/auth";
import { get_breeds, get_breeds_cats, clear_error } from "./actions/form";
import { 
  get_bookings, 
  get_upcoming_recur_bookings,
  get_past_nonrecur_bookings,
  get_past_recur_bookings
} from "./actions/booking";

ReactGA.initialize("UA-217809771-1");

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}


var hist = createBrowserHistory();

class App extends Component {

  componentDidMount() {
    console.log("User Agent: " + browserName)
    console.log("Mobile : " + isMobile)
    localStorage.setItem("isMobile", isMobile)

    const token = localStorage.getItem("token");
    if (token) {
      store.dispatch(loadUser());
      store.dispatch(get_bookings());
      store.dispatch(get_upcoming_recur_bookings());
      store.dispatch(get_past_nonrecur_bookings());
      store.dispatch(get_past_recur_bookings());

    }
    store.dispatch(get_breeds());
    store.dispatch(get_breeds_cats());
    store.dispatch(clear_error());

    window.$crisp = [];
    window.$crisp.push(["safe", true]);
    window.CRISP_WEBSITE_ID = `${process.env.REACT_APP_CRISP_WEBSITE_ID}`;
    (function () {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={hist}>
          <Fragment>
            <Switch>
              <AuthenticationRoute
                path="/dashboard"
                component={RenderDashboard}
              />
              <AuthenticationRoute path="/wizard" component={RenderWizard} />
              <Route path="/search" component={ResultsPage} />
              <Route path="/providers/:id" component={SinglePetProviderView} />
              <Route path="/providers/:id/photos" component={ProviderGallery} />
              <Route path="/searching" component={ErrorsPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/signup" component={SignUpPage} />
              <Route path="/social_signup" component={SignUpGoogle} />
              <Route path="/verify" component={VerifySignupCode} />
              <Route path="/forgot_password" component={ForgotPassword} />
              <Route path="/verify_code" component={Verifyemailcode} />
              <Route path="/accounts/confirm/:code" component={VerifySignup} />
              <Route path="/verify_code_reset" component={verifyemailreset} />
              <Route path="/reset-password" component={ResetPassward} />
              <Route path="/payments" component={Payments} />
              <Route path="/select_account" component={SelectAccount} />
              <Route path="/about" component={AboutUs} />
              <Route path="/faqs" component={FQAs} />
              <Route path="/tips" component={Tips} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/terms" component={Terms} />
              <Route path="/cancellation" component={CancellationPolicy} />
              <Route path="/contactpetprovider" component={ContactPetProvider}/>
              <Route path="/filter" component={Filter} />
              <Route path="/profileinfo" component={ProfileInfo} />
              <Route path="/petprovider/:id" component={RenderBookingWizard} />
              <Route path="/bookings/:id" component={BookingDetails}/>
              <Route path="/gallery/provider/:id" component={ProviderGallery}/>
              <Route exact path="/" component={HomePage} />
            </Switch>
          </Fragment>
        </Router>
      </Provider>
    );
  }
}

export default App;
