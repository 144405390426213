import React from "react";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Input from "@material-ui/core/Input";
import Spinner from "../assets/home_load.gif";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";

import {
  getPetData,
  user_delete_pet,
  clear_state_pets,
  init_fetch,
  deinit_fetch,
  user_update_pet,
  clear_error
} from "../actions/form.js";

import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import FormLabel from "@material-ui/core/FormLabel";

import {

  get_bookings,
  confirm_booking,
  delete_booking,
  cant_cancel,
  add_to_google_calendar,
  clear_add_calendar_id

} from "../actions/booking";
import PetCardDetailsPet from "./PetCardDetailsPet";
import moment from "moment";

const Accordion = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    marginBottom: theme.spacing(1),
    color: "black",
  },
  parentDiv: {
    margin: theme.spacing(1),
  },
  underline: {
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  helpText2: {
    paddingTop: theme.spacing(0),
    marginLeft: theme.spacing(3),
    height: "100%",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "blue",
  },
  line: {
    textAlign: "center",
    backgroundColor: "#fafafa",
    width: "100%",
    borderRadius: "3px",
    border: "1px solid #cfd7de",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    "& > label": {
      paddingLeft: theme.spacing(2),
    },
  },
  line_: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  spin: {
    height: 50,
    width: 50,
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  accordion: {
    margin: theme.spacing(1, 0),
  },
  button: {
    backgroundColor: "#b53f3fbd",
    float: "right",
    margin: theme.spacing(0, 0, 1),
  },
  submitButton: {
    backgroundColor: "#663399",
    marginLeft: theme.spacing(2),
    float: "right",
  },
  inputSelect: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    width: "100%",
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  inputSkeleton: {
    fontSize: "13px",
    color: "#1b1f23",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  formGroupLabel: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  titleBlock: {
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    fontSize: "20px",
    color: "black",
  },
  checkbox: {
    float: "right",
    display: "flex",
    justifyContent: "flex-end",
  },

  root_: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: "Dosis",
    fontWeight: "bold",
    marginLeft: "20px",
    color: "#23286B",
    display: "flex",
    alignItems: "center",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading_: {
    fontSize: theme.typography.pxToRem(15),
    color: "blue",
  },
  secondaryHeading__: {
    fontSize: theme.typography.pxToRem(15),
    color: "green",
  },
  secondaryHeading___: {
    fontSize: theme.typography.pxToRem(15),
    color: "grey",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
    [theme.breakpoints.down('700')]: {
      flexDirection: 'column',
      '& div': {
        flexBasis: "100%",
        width: "100%",
        margin: '5px 0',
        flexDirection: 'column',
      },
    }
  },
  column: {
    flexBasis: "33.33%",
    [theme.breakpoints.down('700')]: {
      display: "flex",
    }
  },
  column_message: {
    flexBasis: "33.33%",
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down("550")]: {
      width: "100%"
    },
  },
  column_: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center"
  },
  containerInfo: {
    margin: "10px -8px"
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  paddingMob: {
    [theme.breakpoints.down('400')]: {
      padding: "0",
    }
  },
  root_time: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  formLabel_: {
    fontSize: "13px",
    color: "red",
    fontWeight: "600",
    marginTop: "20px",
    marginBottom: theme.spacing(1),
  },
  input: {
    fontSize: "13px",
    color: "#1b1f23",
    border: "1px solid #cfd7de",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "&::after": {
      borderBottom: "1px solid #949494",
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

function convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(":");
    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];
    var hours;
    var period;

    if (HH === 0) {
        hours = "12";
        period = 'AM';
    } else if (HH < 12) {
        hours = HH.toString().padStart(2, "0");
        period = 'AM';
    } else if (HH === 12) {
        hours = "12";
        period = 'PM';
    } else {
        hours = (HH - 12).toString().padStart(2, "0");
        period = 'PM';
    }

    var newFormatTime = hours + ":" + min.padStart(2, "0") + ' ' + period;
    return newFormatTime;
}
  
function DashboardViewSingleBookingProvider(props) {
  const classes = useStyles();

  const [date_past, setDate_past] = React.useState(false);
  const [date_past_buttons, setDate_past_buttons] = React.useState(false);
  const [reviewable, setReview] = React.useState(false);

  var gapi = window.gapi
  var CLIENT_ID = "260613450003-i1j93abecf0addpplo1dbcdf7trilhii.apps.googleusercontent.com"
  var API_KEY = "AIzaSyCszUNVVZ6chsUW4aLKDqt-aMfl1L4QA4Q"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar.events"


  const addToGoogleCalendar = () => {
     if (props.booking) {
      // Sample values for demonstration
      const start_date = props.booking?.start_date;
      const start_time = props.booking?.start_time;
      const end_date = props.booking?.end_date;
      const end_time = props.booking?.end_time;

      // Combine date and time strings
      const startDateTimeString = `${start_date}T${start_time}`;
      const endDateTimeString = `${end_date}T${end_time}`;

      // Create Moment objects
      const startMoment = moment(startDateTimeString);
      const endMoment = moment(endDateTimeString);

      // Convert to ISO 8601 format
      const startISOString = startMoment.toISOString();
      const endISOString = endMoment.toISOString();

      const insert_event = () => {
        gapi.load('client:auth2', () => {
            console.log('loaded client')

            gapi.client.init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              discoveryDocs: DISCOVERY_DOCS,
              scope: SCOPES,
            })

            gapi.client.load('calendar', 'v3', () => console.log('bam!'))
            gapi.auth2.getAuthInstance().signIn()
            .then(() => {
              
              var event = {
                'summary': props?.booking?.service?.service?.name,
                'location': props?.booking?.created_by?.address_line_1,
                'description': "Created through Homepetvet",
                'start': {
                  'dateTime': startISOString,
                  'timeZone': 'UTC'
                },
                'end': {
                  'dateTime': endISOString,
                  'timeZone': 'UTC'
                },

                // 'recurrence': [
                //   'RRULE:FREQ=DAILY;COUNT=3'
                // ],

                'attendees': [
                  // {'email': 'joshuajosh423@gmail.com'},
                  {'email': props?.booking?.service_provider?.email}
                ],
                'reminders': {
                  'useDefault': false,
                  'overrides': [
                    {'method': 'email', 'minutes': 24 * 60},
                    {'method': 'email', 'minutes': 12 * 60},
                    {'method': 'popup', 'minutes': 15}
                  ]
                }
              }

              var request = gapi.client.calendar.events.insert({
                'calendarId': 'primary',
                'resource': event,
              })
              props.add_to_google_calendar(props.booking)


              request.execute(event => {
                // console.log(event)
                window.open(event.htmlLink)
              })

              
            })
        })
      }

      insert_event()
      // props.add_to_google_calendar(props.booking)
      
     }
  }

  const cancel_status = async () => {
    if (props.booking) {
      var start = moment(
        `${props.booking.start_date}T${props.booking.start_time}`
      );
      var b = moment(new Date()); // now
      const prime_difference = start.diff(b, "hours");
      if (prime_difference >= 12) {
        // console.log("Can Cancel...")
        await props.confirm_booking(props.booking.id, "cancelled", null);
        props.get_bookings();
        props.get_bookings();
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      } else {
        // console.log("Can't Cancel...")
        props.cant_cancel(props.booking.id);
      }
    }
  };

  React.useEffect(() => {
    if (props.booking !== null) {

      if (props.booking.status === "confirmed") {
        var end_date = moment(
          `${props.booking.end_date}T${props.booking.end_time}`
        );
        var current = moment(new Date());
        const prime_difference = end_date.diff(current, "minutes");
        if (prime_difference <= 0) {
          setReview(true);
        } else {
          setReview(false);
        }
      } else if (props.booking.status === "pending") {
        end_date = moment(
          `${props.booking.end_date}T${props.booking.end_time}`
        );
      } else {
        setDate_past(false);
      }
    }
  }, [props.booking]);

  React.useEffect(() => {
    if (props.booking !== null) {
      if (props.booking.status === "pending") {
        var end_date = moment(
          `${props.booking.end_date}T${props.booking.end_time}`
        );
        var current = moment(new Date());
        const prime_difference = end_date.diff(current, "minutes") + 1;
        if (props.booking.id === 605) {
          console.log("End time difference: " + prime_difference);
        }
        if (prime_difference <= 0) {
          setDate_past(true);
        } else {
          setDate_past(false);
        }
      }
    }
  }, [props.booking]);

  React.useEffect(() => {
    if (props.booking !== null) {
      if (props.booking.status === "pending") {
        var end_date = moment(
          `${props.booking.end_date}T${props.booking.end_time}`
        );
        var current = moment(new Date());
        const prime_difference = end_date.diff(current, "minutes") + 1;
        if (props.booking.id === 605) {
          console.log("Start time difference: " + prime_difference);
        }
        if (prime_difference <= 0) {
          setDate_past_buttons(true);
        } else {
          setDate_past_buttons(false);
        }
      }
    }
  }, [props.booking]);

  const confirm = async () => {
    let appointment_object;
    if (props.booking.notes === null) {
      appointment_object = {
        start_time: props.booking.start_time,
        start_date: props.booking.start_date,
        end_time: props.booking.end_time,
        end_date: props.booking.end_date,
        notes: props.booking.service.service.name,
        recurring: props.booking.recurring,
        interval: props.booking.interval,
        created_by: props.booking.service_provider.id,
      };
    } else {
      appointment_object = {
        start_time: props.booking.start_time,
        start_date: props.booking.start_date,
        end_time: props.booking.end_time,
        end_date: props.booking.end_date,
        notes: props.booking.notes,
        recurring: props.booking.recurring,
        interval: props.booking.interval,
        created_by: props.booking.service_provider.id,
      };
    }
    await props.confirm_booking(props.booking.id, "confirmed", appointment_object);
    props.fetch_again();
    
  };

  React.useEffect(() => {
    if (props.bookings.confirmed && props.messages.notify_status !== null && props.messages.notify_status !== "Error charging client for Booking") {
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bookings.confirmed, props.messages.notify_status]);

  const decline = async () => {
    await props.confirm_booking(props.booking.id, "rejected", null);
    props.fetch_again();
    setTimeout(() => {
      window.location.reload()
    }, 2000);
  };

  React.useEffect(() => {
    if (props.form.booking_confirm_page) {
      props.get_bookings();
      props.get_bookings();
      // window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.booking_confirm_page]);


  const [default_start_] = React.useState(
    new Date(`${props.booking.start_date}T${props.booking.start_time}`)
    );
  const [default_start] = React.useState(
   convertTo12Hour(`${default_start_.getHours()}:${default_start_.getMinutes()}`)
  );

  const [default_end_] = React.useState(
    new Date(`${props.booking.start_date}T${props.booking.end_time}`)
    );
  const [default_end] = React.useState( 
    convertTo12Hour(`${default_end_.getHours()}:${default_end_.getMinutes()}`)
  );

  const [open_] = React.useState(true);

  let alert;
  if (props.messages.notify_status !== null) {
    console.log("props.messages.notify_status")
    console.log(props.messages.notify_status)
    alert = <div className="alerts">{props.messages.notify_status}</div>;
  }

  return (
    <div key={props.key} className={classes.parentDiv}>
      {props.booking !== null ? (
        <div>
          <div className={classes.root_}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
                className={classes.paddingMob}
              >
               <div style={{ 
                   width: "100%", 
                   display: 'flex', 
                   justifyContent: 'space-between',
                   flexDirection: 'row'
                }}>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                      <img
                        src={props.booking.service.service.icon}
                        height="17px"
                        width="17px"
                        style={{ marginRight: "10px" }}
                        alt={props.booking.service.service.name}
                      />{" "}
                      {props.booking.service.service.name}
                      
                    </span>
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{props?.booking?.created_by?.first_name}{" "}
                        {props?.booking?.created_by?.last_name}
                     </span>
                </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{`${moment(props.booking?.start_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}`}
                     </span>
                </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{`${moment(props.booking?.end_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}`}
                     </span>
                </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{default_start}
                     </span>
                </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className={classes.column}>
                    <span className={classes.heading}>
                        {" "}{default_end}
                     </span>
                </div>
                </div>
              <div style={{width: '100%' }}>
                <div className={classes.column_}>
                  <Typography>
                    {props.booking.status === "pending" ? (
                      <span className={classes.secondaryHeading_}>
                        {" "}
                        {props.booking.status}
                      </span>
                    ) : null}
                    {props.booking.status === "confirmed" ? (
                      <span className={classes.secondaryHeading__}>
                        {" "}
                        {props.booking.status}
                      </span>
                    ) : null}
                    {props.booking.status === "cancelled" ? (
                      <span className={classes.secondaryHeading___}>
                        {" "}
                        {props.booking.status}
                      </span>
                    ) : null}
                    {props.booking.status === "rejected" ? (
                      <span className={classes.secondaryHeading___}>
                        {" "}
                        declined
                      </span>
                    ) : null}
                  </Typography>
                </div>
              </div>
             </div> 

              </AccordionSummary>
              <Grid item xs={12} className={classes.root_time}>
                {date_past ? (
                  <FormLabel component="label" className={classes.formLabel_}>
                    This booking has past.
                  </FormLabel>
                ) : null}
                <Grid container className={classes.containerInfo} spacing={2}>
                  <Grid item sm={10} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Start time
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      value={default_start}
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                  <Grid item sm={10} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      End time
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      value={default_end}
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      Start date
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      // value={props.booking.start_date}
                      value={moment(props.booking?.start_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}
                      className={classes.line}
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <FormLabel component="label" className={classes.formLabel}>
                      End date
                    </FormLabel>
                    <TextField
                      id="name"
                      name="name"
                      // value={props.booking.end_date}
                      value={moment(props.booking?.end_date, 'YYYY-MM-DD').toDate().toString().substring(0, 11)}
                      className={classes.line}
                      disableUnderline
                      InputProps={{ classes: { underline: classes.underline } }}
                    />
                  </Grid>
                </Grid>
                <FormLabel component="label" id="petName" className={classes.formLabel}>
                  Pet(s)
                </FormLabel>
                <div className={classes.column}>
                  {props.booking.pets.map((pet, i) => (
                    <PetCardDetailsPet pet={pet} />
                  ))}
                </div>
              </Grid>
              <AccordionDetails className={classes.details}>
                <div style={{ marginLeft: "0px" }}>
                  <div className={classes.column}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontFamily: "Dosis",
                        fontWeight: "bold",
                        color: "#23286D",
                      }}
                    >
                      Your pet owner's details
                    </span>
                    <br />
                    <span>
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "Dosis",
                          fontWeight: "bold",
                          color: "#23286G",
                        }}
                      >
                        Name:{" "}
                      </span>{" "}
                      {props.booking.created_by.first_name}{" "}
                      {props.booking.created_by.last_name}
                    </span>
                    <br />
                    {props.booking.recurring === true ? (
                      <span>
                        <span
                          style={{
                            fontSize: "18px",
                            fontFamily: "Dosis",
                            fontWeight: "bold",
                            color: "#23286G",
                          }}
                        >
                          Recurring:{" "}
                        </span>{" "}
                        {"Yes"}
                        <br />
                      </span>
                    ) : null}
                    {props.booking.interval !== "none" &&
                      props.booking.interval !== null ? (
                      <span>
                        <span
                          style={{
                            fontSize: "18px",
                            fontFamily: "Dosis",
                            fontWeight: "bold",
                            color: "#23286G",
                          }}
                        >
                          Interval:{" "}
                        </span>{" "}
                        <Chip
                          style={{ marginRight: "10px" }}
                          label={props.booking.interval}
                          icon={false}
                        />
                        <br />
                      </span>
                    ) : null}
                    <span>
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "Dosis",
                          fontWeight: "bold",
                          color: "#23286G",
                        }}
                      >
                        City:{" "}
                      </span>
                      {props.booking.created_by.city}
                    </span>
                    <br />
                    <span>
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Dosis",
                          fontWeight: "bold",
                          color: "#23286G",
                        }}
                      >
                        Address:{" "}
                      </span>
                      {props.booking.created_by.address_line_1}
                    </span>
                    <br />
                  </div>
                </div>
                <div className={classes.column} />
                <Divider />
                <div className={classes.column_message}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "Dosis",
                      fontWeight: "bold",
                      color: "#23286D",
                    }}
                  >
                    Message
                  </span>
                  <br />
                  <Input
                    disableUnderline
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Share any important notes about your pet(s)."
                    value={props?.booking?.notes}
                    className={classes.input}
                  />
                </div>
              </AccordionDetails>
              <Divider />    

              {!date_past_buttons ? (
                <AccordionActions>
                  <Button
                    size="small"
                    disabled={
                        props.booking.status === "confirmed" ||
                        props.booking.status === "cancelled" ||
                        props.booking.status === "rejected"
                        ? true
                        : false
                    }
                    variant="outlined"
                    onClick={confirm}
                    color="primary"
                  >
                    {"Confirm"}
                    {props.bookings.confirmed_loading === true &&
                      props.bookings.confirm_id === props.booking.id ? (
                      <div style={{ marginLeft: "10px" }}>
                        <img src={Spinner} alt="" height="16px" width="16px" />
                      </div>
                    ) : null}
                  </Button>

                  {(props.booking.status !== "cancelled" && props.booking.status !== "rejected") ?
                   <Button
                      size="small"
                      onClick={async () => {
                        
                        await addToGoogleCalendar()
                        

                        // addToGoogleCalendar(() => {
                        //     props.add_to_google_calendar(props.booking);
                        // });

                    }}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: "10px" }}
                    >
                      <span>{" "} Add to google calendar</span>
                      
                      {/*We are adding to calendar and ID matches the booking */}
                      {props.add_to_calendar_load === true && props.add_to_calender_id === props.booking.id  ? (
                        <div style={{ marginLeft: "10px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="16px"
                            width="16px"
                          />
                        </div>
                      ) : null}

                    </Button>: null}

                  {props.booking.status === "pending" ? (
                    <Button
                      size="small"
                      disabled={
                          props.booking.status === "confirmed" ||
                          props.booking.status === "cancelled" ||
                          props.booking.status === "rejected"
                          ? true
                          : false
                      }
                      variant="outlined"
                      onClick={decline}
                      color="primary"
                    >
                      {"Decline"}
                      {props.bookings.rejected_loading === true &&
                        props.bookings.rejected_id === props.booking.id ? (
                        <div style={{ marginLeft: "10px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="16px"
                            width="16px"
                          />
                        </div>
                      ) : null}
                    </Button>
                  ) : null}

                  {reviewable === false ? ( // booking has not been confirmed
                    <Button
                      size="small"
                      disabled={
                        props.booking.status === "cancelled" ||
                          props.booking.status === "rejected"
                          ? true
                          : false
                      }
                      variant="outlined"
                      onClick={cancel_status}
                      style={{ color: "red", marginLeft: "10px" }}
                      color="primary"
                    >
                      {"Not Available"}
                      {props.bookings.cancelled_loading === true &&
                        props.bookings.cancel_id === props.booking.id ? (
                        <div style={{ marginLeft: "10px" }}>
                          <img
                            src={Spinner}
                            alt=""
                            height="16px"
                            width="16px"
                          />
                        </div>
                      ) : null}
                    </Button>
                  ) : null}

                </AccordionActions>
              ) : null}
              
            </Accordion>
          </div>
          
          {props.add_to_calendar_success &&
            props.add_to_calender_id === props.booking.id ? (
            <Collapse in={open_}>
              <Grid item xs={12}>
                <Alert
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        props.clear_add_calendar_id();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <div className={classes.message}>{"This Appointment has been successfully added to your google calendar."}</div>
                </Alert>
              </Grid>
            </Collapse>
          ) : null}
          {props.messages.notify_status &&
            props.messages.notify_id === props.booking.id ? (
            <Collapse in={open_}>
              <Grid item xs={12}>
                <Alert
                  severity={
                    props.messages.notify_status === "You can only cancel a booking before 12 hours from the time it's scheduled to start." ||
                    props.messages.notify_status === "Error charging client for Booking" ||
                    props.messages.notify_status === "Please add a bank account where earnings from bookings will be deposited before confirming a booking"
                      ? "error"
                      : "success"
                  }
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        props.clear_error();
                        // console.log("Reload...")
                        // window.location.href = '/dashboard'
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <div className={classes.message}>{alert}</div>
                </Alert>
              </Grid>
            </Collapse>
          ) : null}
        </div>
      ) : (
        <div>
          <Skeleton
            variant="rect"
            width="100%"
            className={classes.accordion}
            height="3em"
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  fetch: state.pets.fetch,
  types: state.pets.pet_types,
  loading: state.pets.isLoading,
  error: state.errors,
  form: state.forms,
  progress: state.services.progress,
  pets: state.pets.pets,
  admin_pets: state.pets.pet_types,
  breeds_types: state.pets.breeds,
  breeds_cats: state.pets.breeds_cats,
  bookings: state.booking,
  messages: state.messages,

  
  add_to_calendar_load: state.booking.add_to_calendar_load,
  add_to_calender_id: state.booking.add_to_calender_id,
  add_to_calendar_success: state.booking.add_to_calendar_success,

});

export default connect(mapStateToProps, {
  getPetData,
  user_delete_pet,
  clear_state_pets,
  init_fetch,
  deinit_fetch,
  user_update_pet,
  get_bookings,
  confirm_booking,
  clear_error,
  delete_booking,
  cant_cancel,
  add_to_google_calendar,
  clear_add_calendar_id
})(DashboardViewSingleBookingProvider);
