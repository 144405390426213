/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Paper from "@material-ui/core/Paper";
import { useState } from 'react';
// import Avatar from "../assets/avatar-01.png";
// import Mark from '../assets/checked.svg';
// import Link from '@material-ui/core/Link';
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserHomepageNavbar';
import { loadUser } from '../actions/auth.js';

import { 
  get_availability_data,
   clear_aval, 
   getPetData, 
   clear_error, 
   make_booking_first_signal_reset, 
   get_payment_method,
   setToNull
 } from '../actions/form.js';
import { create_booking } from '../actions/booking.js';
import { getproviderdetails } from '../actions/results';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddPetsBooking from '../components/AddPetsBooking.js';
import Redirect from '../views/Redirect.js';
import Spinner from '../assets/home_load.gif';
import Footprint from '../assets/footprint.svg';
import { createMessage } from '../actions/messages';
import { useDispatch } from 'react-redux';
// import AppointmentsBook from '../views/AppointmentsBook';

import TimelineIcon from '@material-ui/icons/Timeline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoopIcon from '@material-ui/icons/Loop';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import DetailServiceBooking from './DetailServiceBooking';

import moment from 'moment';
import holidays from '@date/holidays-us';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from "@material-ui/core/styles";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { FormGroup } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import * as Scroll from 'react-scroll';
import Geocode from "react-geocode";
import HaversineGeolocation from "haversine-geolocation";
// Geocode.setApiKey("AIzaSyCQcx5SoD1hCaNt1-LcQREL-MGdMD6kato");
Geocode.setApiKey("AIzaSyCQcx5SoD1hCaNt1-LcQREL-MGdMD6kato");
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");


var scroll = Scroll.animateScroll;

const theme = createTheme({
  overrides: {
    MuiPickersClock: {
      pin: {
        backgroundColor: '#3a9643'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#3a9643'
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#3a9643',
        thumb: {
          backgroundColor: '#3a9643'
        },
        '& > div': {
          backgroundColor: '#3a9643',
          border: '14px solid #3a9643'
        }
      },
      thumb: {
        backgroundColor: '#3a9643'
      },
      noPoint: {
        backgroundColor: '#3a9643'
      }
    },
    MuiFormHelperText: {
      root: {
        position: 'apsolute'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#3a9643'
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    paddingTop: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.down("550")]: {
      margin: "0 !important",
    },
  },
  root_: {
    flexGrow: 1
  },
  page: {
    padding: '15px 30px 14px 35px',
    width: '100%',
    borderColor: 'black',
    background: 'white',
    shadowColor: "#000",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 2.62,

    minHeight: '400px',
    borderRadius: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('768')]: {
      maxWidth: '375px',
      margin: '0 auto',
      padding: '45px 20px 44px 20px'
    },
    [theme.breakpoints.down('450')]: {
      padding: '10px'
    }
  },
  calendar: {
    [theme.breakpoints.down('350')]: {
      '& > button': {
        width: '16px'
      }
    }
  },
  head: {
    marginBottom: '48px',
    display: 'flex',
    alignItems: 'flex-start'
  },
  avatar: {
    marginRight: '95px',
    width: '184px',
    maxWidth: '184px',
    textAlign: 'center',
    [theme.breakpoints.down('768')]: {
      width: '140px',
      maxWidth: '140px',
      marginRight: '30px'
    }
  },
  avatarImg: {
    width: '184px',
    maxWidth: '184px',
    height: '184px',
    maxHeight: '184px',
    [theme.breakpoints.down('768')]: {
      width: '140px',
      maxWidth: '140px',
      height: '100%'
    },
    '& > img': {
      width: '100%'
    }
  },
  numberStar: {
    marginLeft: '7px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#000000'
  },
  starRatings: {
    marginTop: '25px',
    marginLeft: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('768')]: {
      marginTop: '0px',
      position: 'relative',
      left: '90px',
      top: '10px'
    }
  },
  headInfo: {
    textAlign: 'left',
    width: '100%'
  },
  headName: {
    marginTop: '20px',
    maxWidth: 'fit-content',
    marginBottom: '8px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
    color: '#222222',
    position: 'relative',
    [theme.breakpoints.down('768')]: {
      marginTop: 0,
      marginBottom: '12px',
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  background_check: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'grey',
    fontSize: '55px'
  },
  headName_: {
    marginTop: '5px',
    maxWidth: 'fit-content',
    marginBottom: '5px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
    color: '#222222',
    position: 'relative',
    [theme.breakpoints.down('768')]: {
      marginTop: 0,
      marginBottom: '4px',
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  headName__: {
    marginTop: '10px',
    maxWidth: 'fit-content',
    marginBottom: '6px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
    color: '#222222',
    position: 'relative',
    [theme.breakpoints.down('768')]: {
      marginTop: 0,
      marginBottom: '4px',
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  headMark: {
    position: 'absolute',
    right: '-26px',
    top: '0',
    [theme.breakpoints.down('768')]: {
      right: '-26px',
      left: 'auto'
    }
  },
  headLocation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '20px'
  },
  headLocationImg: {
    marginRight: '20px'
  },
  headLocationTitle: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#383F45'
  },
  headLocationSubtitle: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#4F4F4F',
    marginBottom: '30px'
  },
  headLocationBtn: {
    marginTop: '14px',
    width: '149px',
    height: '41px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#156981',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background 0.4s',
    [theme.breakpoints.down('768')]: {
      marginTop: '12px',
      fontSize: '20px',
      height: '36px'
    },
    '&:hover': {
      background: '#88CCDD'
    }
  },
  title: {
    marginBottom: '5px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    // lineHeight: '33px',
    // textDecorationLine: 'underline',
    color: '#222222',
    [theme.breakpoints.down('768')]: {
      marginBottom: '15px',
      marginTop: '15px',
      fontSize: '22px',
      // lineHeight: '24px'
    }
  },
  title_: {
    // maxWidth: '624px',
    marginBottom: '4px',
    alignItems: 'center',
    fontWeight: '350',
    fontSize: '32px',
    marginTop: '25px',
    // lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  title__: {
    maxWidth: '624px',
    marginBottom: '4px',
    alignItems: 'center',
    fontWeight: '350',
    fontSize: '18px',
    lineHeight: '58px',
    color: 'black',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  description: {
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#383F45',
    [theme.breakpoints.down('768')]: {
      fontSize: '14px',
      lineHeight: '18px'
    }
  },
  services: {
    width: '100%'
    // [theme.breakpoints.down("1110")]: {
    //   width: '40%',
    // },
    // [theme.breakpoints.down("950")]: {
    //   maxWidth: '140px'
    // },
  },
  servicesWraper: {
    // width: '1071px',
    display: 'flex',
    cursor: 'pointer',
    // marginBottom: '20px',
    // flexWrap: 'wrap',
    // flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between',
    // [theme.breakpoints.down("1110")]: {
    //   width: '100%',
    // },
    // [theme.breakpoints.down("950")]: {
    //   flexDirection: 'column'
    // },
    [theme.breakpoints.down("700")]: {
      '& > div': {
        width: '100%'
      },
    },
  },
  servicesItem: {
    marginBottom: '5px',
    marginLeft: '10px',
    marginRight: '10px',
    // padding: '11px 0px 8px 0px',
    // width: 'calc((100% - 40px) / 2)',
    display: 'flex',
    height: '150px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#F2F2F2',
    border: '2px solid #E0E0E0',
    borderRadius: '10px',
    '&:hover': {
      borderColor: 'blue'
    },
    // [theme.breakpoints.down("1110")]: {
    //   width: 'calc((100% - 20px) / 2)',
    // },
    // [theme.breakpoints.down("950")]: {
    //   width: '100%',
    // },
    // [theme.breakpoints.down("768")]: {
    //   padding: '5px 0px 5px 0px',
    //   marginBottom: '8px',
    // },
  },
  servicesTitle: {
    marginBottom: '4px',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '40px',
    color: '#1B5E20',
    [theme.breakpoints.down('768')]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  servicesTitle_: {
    marginBottom: '4px',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '40px',
    color: '#1B5E20',
    [theme.breakpoints.down('768')]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  servicesPrice: {
    marginBottom: '13px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#000000',
    [theme.breakpoints.down('768')]: {
      marginBottom: '5px'
    }
  },
  servicesBtn: {
    width: '91px',
    height: '25px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#156981',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background 0.4s',
    [theme.breakpoints.down('768')]: {
      width: '70px',
      height: '20px'
    },
    '&:hover': {
      background: '#88CCDD'
    }
  },
  contant: {
    marginTop: '20px',
    // display: 'flex',
    // alignItems: 'flex-start',
    // justifyContent: 'space-between',
    // [theme.breakpoints.down("1110")]: {
    //   flexDirection: 'row-reverse'
    // },
    // [theme.breakpoints.down("950")]: {
    //   maxWidth: '680px',
    //   margin: '0 auto'
    // },
  },
  contantInfo: {
    width: '100%',
    maxWidth: '564px',
    [theme.breakpoints.down('1250')]: {
      maxWidth: '50%'
    },
    [theme.breakpoints.down('950')]: {
      maxWidth: '500px',
      marginRight: '20px'
    }
  },
  about: {
    //  marginBottom: '76px',
    maxWidth: '444px',
    [theme.breakpoints.down('950')]: {
      marginBottom: '30px'
    }
  },
  gallery: {
    marginBottom: '106px',
    maxWidth: '444px',
    [theme.breakpoints.down('950')]: {
      marginBottom: '30px'
    }
  },
  post: {
    marginBottom: '34px',
    '&:last-child': {
      marginBottom: '28px'
    }
  },
  postHead: {
    marginBottom: '14px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  postAvatar: {
    marginRight: '17px',
    maxHeight: '53px',
    maxWidth: '53px'
  },
  postAvatarImg: {
    width: '100%'
  },
  rating: {
    marginLeft: 'auto'
  },
  postHeadName: {
    marginBottom: '7px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#383F45'
  },
  buttonAside: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282'
  },
  postText: {
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#383F45',
    [theme.breakpoints.down('768')]: {
      fontSize: '14px',
      lineHeight: '18px'
    }
  },
  postsMore: {
    display: 'block',
    margin: '0 auto',
    width: '87px',
    height: '24px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#BDBDBD',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer'
  },
  imageSelector: {
    fontSize: '8rem',
    width: theme.spacing(26),
    height: theme.spacing(26),
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  reviews: {
    // marginTop: '10px',
    marginBottom: '40px'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  },
  content: {
    //  padding: theme.spacing(2),
    paddingTop: 0,
    maxWidth: '100%'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),

    [theme.breakpoints.down("650")]: {
      flexWrap: 'wrap',
      '& > div': {
        marginRight: '0 !important'
      }
    },
  },
  wrapper2: {
    display: 'flex',
    // justifyContent: 'space-between',
    // padding: theme.spacing(1, 0)
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2)
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(2)
  },
  formLabe: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 1)',
    fontWeight: '800',
    marginBottom: theme.spacing(2)
  },
  formLabel_: {
    fontSize: '13px',
    color: 'red',
    fontWeight: '600',
    marginBottom: theme.spacing(2)
  },
  inputSmall: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    // marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  pageTitle: {
    maxWidth: '624px',
    marginBottom: '2px',
    // alignItems: 'center',
    fontWeight: '200',
    fontSize: '22px',
    // lineHeight: '58px',
    color: '#23286B'
  },
  pageTitle_: {
    maxWidth: '624px',
    marginBottom: '2px',
    // alignItems: 'center',
    fontWeight: '200',
    fontSize: '15px',
    // lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down("768")]: {
      padding: "10px 0"
    },
  },
  button: {
    // marginLeft: '40px',
    marginTop: '10px',
    alignItems: 'center',
    color: '#FF3D00',
    justifyContent: 'center',
    backgroundColor: '#fff!important',
    border: '1.5px solid #FF3D00',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#F0FFF0!important'
    }
  },
  button_disabled: {
    // marginLeft: '40px',
    marginTop: '10px',
    alignItems: 'center',
    color: '#FF3D00',
    justifyContent: 'center',
    backgroundColor: '#fff!important',
    border: '1.5px solid grey',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#F0FFF0!important'
    }
  },
  // headMark: {
  //   position: 'absolute',
  //   right: '-26px',
  //   top: '0',
  //   [theme.breakpoints.down('768')]: {
  //     right: '-26px',
  //     left: 'auto'
  //   }
  // },
  titleBlock: {
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    // marginLeft: '20px',
    fontSize: '20px',
    color: 'black'
  },
  line: {
    textAlign: 'center',
    backgroundColor: '#fafafa',
    width: '100%',
    borderRadius: '3px',
    border: '1px solid #cfd7de',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    '& > label': {
      paddingLeft: theme.spacing(2)
    },

  },
  underline: {
    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    }
  }
}));

function PetProviderInfo(props) {
  const classes = useStyles();
  const [book_id] = useState(props.id);

  // Availability
  // const [_id] = useState(localStorage.getItem('book_provider_id'));
  React.useEffect(() => {
    props.get_availability_data(book_id);
    props.get_payment_method();
    props.get_payment_method();
  }, []);

  React.useEffect(() => {
    if (props.payment_methods) {
      const primary_card = props.payment_methods.filter((card) => card.is_primary === true);
      if(primary_card?.length > 0){
        localStorage.setItem("owner_primary_card", primary_card[0]?.id)
      }
    }
  }, [props.payment_methods]);

  const [data, setData] = useState('');
  const [data_, setData_] = useState('');
  // const fetching = () => {
  //   // console.log("Refreshing secondary....")
  //   props.clear_aval();
  //   props.get_availability_data(book_id);
  // };

  React.useEffect(() => {
    if (props.availability_data_next !== null) {
      props.get_availability_data(book_id, props.availability_data_next);
    }

    if(props.availability_data_next === null){
      setTimeout(() => {
        console.log("Here::::")
        props.setToNull(); // Wait a bit for last fetch to complete...
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.availability_data_next]);

  React.useEffect(() => {
    if (props.availability) {
      const list = [];
      function getWeeklyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 7);
        }
        return result;
      }
      function getBiWeeklyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 14);
        }
        return result;
      }

      function getMonthlyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 30);
        }
        return result;
      }

      function getYearlyDates(start, end) {
        let date = new Date(start);
        const endDate = new Date(end);

        const result = [];
        while (date <= endDate) {
          result.push(date);
          date = new Date(date);
          date.setUTCDate(date.getUTCDate() + 365);
        }
        return result;
      }
      props.availability.filter((availability) => {
        // console.log(availability)
        const end_time = availability.end_time;
        const start_time = availability.start_time;
        // const day = availability.day;
        const start_date = availability.start_date;
        const end_date = availability.end_date;

        var dateString_start = start_date + ' ' + start_time;
        var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArray = reggie.exec(dateString_start);
        var dateObject_start = new Date(
          +dateArray[1],
          +dateArray[2] - 1, // Careful, month starts at 0!
          +dateArray[3],
          +dateArray[4],
          +dateArray[5],
          +dateArray[6]
        );

        var dateString_start_endtime = start_date + ' ' + end_time;
        var reggie2 = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArra = reggie2.exec(dateString_start);
        // eslint-disable-next-line no-redeclare
        var dateObject_start = new Date(
          +dateArra[1],
          +dateArra[2] - 1, // Careful, month starts at 0!
          +dateArra[3],
          +dateArra[4],
          +dateArra[5],
          +dateArra[6]
        );

        var dateString_end = end_date + ' ' + end_time;
        var reggie3 = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArr = reggie3.exec(dateString_end);
        var dateObject_end = new Date(
          +dateArr[1],
          +dateArr[2] - 1, // Careful, month starts at 0!
          +dateArr[3],
          +dateArr[4],
          +dateArr[5],
          +dateArr[6]
        );

        const id = availability.id;
        // const notes = availability.notes;
        const recur = availability.recurring
        const object_ = {
          recurring: availability.recurring,
          interval: availability.interval,
          end: dateString_end
        }
        let object;
        if (recur === true) {
          if (availability.interval === "weekly") {
            const count = getWeeklyDates(start_date, end_date).length
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=WEEKLY;COUNT=${count}`,
              location: object_
            }
            // console.log(object)
            list.push(object);
            // console.log(getWeeklyDates(start_date, end_date))
            // console.log(availability)
          } else if (availability.interval === "biweekly") {
            const count = getBiWeeklyDates(start_date, end_date).length
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=WEEKLY;COUNT=${count};INTERVAL=2`,
              location: object_
            }
            //console.log(count)
            list.push(object);
            //console.log(getMonthlyDates(start_date, end_date))
            //console.log(availability)
          }
          else if (availability.interval === "monthly") {
            const count = getMonthlyDates(start_date, end_date).length
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=MONTHLY;COUNT=${count}`,
              location: object_
            }
            // console.log(object)
            list.push(object);
            // console.log(getWeeklyDates(start_date, end_date))
            // console.log(availability)
          } else if (availability.interval === "annually") {
            const count = getYearlyDates(start_date, end_date).length
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateString_start_endtime,
              id: id,
              rRule: `FREQ=YEARLY;COUNT=${count}`,
              location: object_
            }
            //console.log(count)
            list.push(object);
            //console.log(getYearlyDates(start_date, end_date))
            //console.log(availability)
          } else {
            object = {
              title: "Not Available",
              startDate: dateObject_start,
              endDate: dateObject_end,
              id: id,
              location: object_
            };
            // console.log(object)
            list.push(object);
          }
        } else {
          object = {
            title: "Not Available",
            startDate: dateObject_start,
            endDate: dateObject_end,
            id: id,
            location: object_
          };
          // console.log(object)
          list.push(object);
        }
        // console.log(object)
        // list.push(object);
      });

      setData(list);

      // for restricting booking times
      const list_ = [];
      props.availability.filter((availability) => {
        // console.log(availability)
        const end_time = availability.end_time;
        const start_time = availability.start_time;
        // const day = availability.day;
        const start_date = availability.start_date;
        const end_date = availability.end_date;

        var dateString_start = start_date + ' ' + start_time;
        var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArray = reggie.exec(dateString_start);
        var dateObject_start = new Date(
          +dateArray[1],
          +dateArray[2] - 1, // Careful, month starts at 0!
          +dateArray[3],
          +dateArray[4],
          +dateArray[5],
          +dateArray[6]
        );

        var dateString_end = end_date + ' ' + end_time;
        var reggie4 = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArr = reggie4.exec(dateString_end);
        var dateObject_end = new Date(
          +dateArr[1],
          +dateArr[2] - 1, // Careful, month starts at 0!
          +dateArr[3],
          +dateArr[4],
          +dateArr[5],
          +dateArr[6]
        );

        const id = availability.id;
        // const notes = availability.notes;
        const recurring = availability.recurring
        const interval = availability.interval

        const object = {
          title: "Not Available",
          startDate: dateObject_start,
          endDate: dateObject_end,
          start_time: start_time,
          end_time: end_time,
          id: id,
          location: {
            'recurring': recurring,
            'interval': interval
          }
        };
        list_.push(object);
      });
      // console.log(list_)
      setData_(list_);
    }
  }, [props.availability, props.availability_data_next]);
  
  // eslint-disable-next-line no-unused-vars
  const [current, setCurrent] = useState('');
  // const [name, setName] = useState(localStorage.getItem('book_provider_name'));

  React.useEffect(() => {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var current_date = year + '-' + month + '-' + date;
    setCurrent(current_date);
  }, [data]);

  React.useEffect(() => {
    localStorage.removeItem('service_object');
    localStorage.removeItem('booking_object');
    localStorage.removeItem('puppies_exist');
    localStorage.removeItem('booking_created');
    localStorage.removeItem('booking_api_object');
    props.getproviderdetails(book_id);
  }, []);

  // Booking
  React.useEffect(() => {
    if (props.auth.user !== null) {
      props.getPetData(props.auth.user.id);
    }
  }, [props.auth.user]);

  const [service_id, setService_ID] = React.useState(null);
  const [noPets, setNopetError] = React.useState(false);
  const [noService, setNo_Service_Error] = React.useState(false);
  const [book_only_owner, setBook_only] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [service_overnight, setOvernight] = React.useState(false);
  // const [additional_rate, setAdditional_rate] = React.useState(null);

  const getService = (service) => {
    setService_ID(service.id);
    localStorage.setItem('service_object', JSON.stringify(service));
    // var pets_array = JSON.parse(localStorage.getItem('pets_array'));
  };
  
  // If service is overnight
  React.useEffect(() => {
    if(service_id !== null){
      console.log(service_id)
      const check  = props?.details?.services?.filter((serv) => serv?.id === service_id)
      if(check[0]?.service?.name === "Overnight"){
        setOvernight(true)
      }else{
        setOvernight(false)
      }
      
    }
  }, [service_id, props?.details?.services]);

  const dispatch = useDispatch();
  // Auto selecting service if user has only one
  React.useEffect(() => {
    if(props.details !== null){
      if (props.details?.services) {
      if (props.details.services.length === 1) {
        // console.log(props.details.services[0])
        setService_ID(props.details.services[0].id);
        localStorage.setItem('service_object', JSON.stringify(props.details.services[0]));
        // var pets_array = JSON.parse(localStorage.getItem('pets_array'));
      }
     }
    }
  }, [props.details?.services]);

  const [recur, setRecur] = React.useState(false);
  const [recurringDayName, setRecurringDayName] = React.useState(
    moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss ZZ').format('dddd')
  );
  const [open_button, setOpenbutton] = React.useState(false);
  const [interval, setInterva] = React.useState("daily");
  const [distance_between, setDistance] = React.useState(null);
  React.useEffect(() => {
    // calculating miles
  const calculate_distance = async (pet_owner_address, pet_provider_address) => {
      // decoding adresses

      let owner_lat = 0;
      let owner_lng = 0;
      let provider_lat = 0;
      let provider_lng = 0;

      await Geocode.fromAddress(pet_owner_address).then(
        (response) => {
          owner_lat = response?.results[0]?.geometry?.location.lat;
          owner_lng = response?.results[0]?.geometry?.location.lng;
        },
        (error) => {
          console.error(error);
        }
      );

      await Geocode.fromAddress(pet_provider_address).then(
        (response) => {
          provider_lat = response?.results[0]?.geometry?.location.lat;
          provider_lng = response?.results[0]?.geometry?.location.lng;
        },
        (error) => {
          console.error(error);
        }
      );

      if (owner_lat && owner_lng && provider_lat && provider_lng) {
        const owner_point = {
          latitude: owner_lat,
          longitude: owner_lng,
        };
        const provider_point = {
          latitude: provider_lat,
          longitude: provider_lng,
        };

        // calculating miles
        const points = [owner_point, provider_point];
        // console.log(points)
        // Distance in miles
        setDistance(
          HaversineGeolocation.getDistanceBetween(points[0], points[1], "mi")
        ); // miles
 
      } 
    }

    if(props.details !== null && props.auth.user){
      calculate_distance(props.auth?.user?.address_line_1, props.details?.address_line_1)
    }
    if(distance_between){
      console.log("distance btwn owner and provider:");
      console.log(distance_between);
    }

  }, [props.details, props.auth.user, distance_between]);

  React.useEffect(() => {
    if (props.form.make_booking_first) {
      setSubmitted(true);
      // setOpenbutton(false)
      dispatch(createMessage('Please click the submit booking button if you have finished filling out the booking form.'));
    }
  }, [props.form.make_booking_first]);


  const setInterval_ = (value) => {
    setInterva(value)
  };

  // eslint-disable-next-line no-unused-vars
  const handleRecur = (e) => {
    if (recur === true) {
      setRecur(false);
    } else {
      setRecur(true);
    }
  };

  const [check_confirm, setCheckConfirm] = React.useState(false);
  const [wrong_dates, setWrongDates] = React.useState(false);

  const [display_start, setDisplayStart] = React.useState(null);
  const [display_end, setDisplayEnd] = React.useState(null);
  const [endBeforeStart, setEndBeforeStart] = React.useState(false);
  const [futureDates, setFutureDates] = React.useState(false);
  const [dateTimeSame, setSameTimeError] = React.useState(false);
  
  React.useEffect(() => {
    setCheckConfirm(false)
  }, []);

  function addTrailzero(time) {
    const [hours, minutes] = time.split(':');
    const formattedHours = hours.length === 1 ? '0' + hours : hours;
    const formattedMinutes = minutes.length === 1 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  }

  // Check that end date is a future time in regards to start date
    const check_timeIsFuture = (date_start, start, end, date_end) => {
        var late = moment(`${date_start} ${start}`, "YYYY-MM-DD HH:mm");
        var future = moment(`${date_end} ${end}`, "YYYY-MM-DD HH:mm");
        var diff = late.diff(future, 'minutes'); // 2seconds

        if(late.diff(future, 'minutes') > 0){
          return false
        }else{
          return true
        }
    }
    const IsFuture = (date, time) => {
        const now_date = moment().format("YYYY-MM-DD") 
        const now_time = moment().format("HH:mm") 

        var late = moment(`${now_date} ${now_time}`, "YYYY-MM-DD HH:mm");
        var future = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
        var diff = late.diff(future, 'minutes'); // 2seconds

        if(late.diff(future, 'minutes') > 0){
          return false
        }else{
          return true
        }
    }

  const check_time_func = () => {
    if(start_time.toString().substring(0, 5) === end_time.toString().substring(0, 5)){
      setSameTimeError(true)
    }else{
      // If service is recurring, check the selected dates first
      if (start_date && start_time && end_date && end_time && recur === true){
        if(IsFuture(start_date, start_time) && IsFuture(end_date, end_time)){
          if(check_timeIsFuture(start_date, start_time, end_time, end_date)){

              if (start_date && start_time && end_date && end_time){
                var set_format = {
                    'Date/Time': `${start_date} ${addTrailzero(start_time)}`
                };
                const time_start = moment(set_format['Date/Time'], "YYYY-MM-DD HH:mm");

                var set_format_ = {
                      'Date/Time': `${end_date} ${addTrailzero(end_time)}`
                  };

                const time_end = moment(set_format_['Date/Time'], "YYYY-MM-DD HH:mm");

                const prime_difference = time_end.diff(time_start, "hours");

                if(prime_difference >= 0){
                  setDisplayStart(time_start._d)
                  setDisplayEnd(time_end._d)
                  setCheckConfirm(true)
                }else{
                  if(prime_difference >= 0){
                    handleBooking()
                  }
                  if(prime_difference < 0){
                    setDisplayStart(time_start._d)
                    setDisplayEnd(time_end._d)
                    setWrongDates(true)
                  }
                }
            }

          }else{
            setEndBeforeStart(true)
            console.log("End date can not be before start date")
          }
        }else{
          setFutureDates(true)
          console.log("Dates selected must be future dates...")
        }
      }else{
        // If service is overnight, first check the dates selected
        if(service_overnight && start_date && start_time && end_date && end_time){
          if(IsFuture(start_date, start_time) && IsFuture(end_date, end_time)){
            if(check_timeIsFuture(start_date, start_time, end_time, end_date)){

                    if (start_date && start_time && end_date && end_time){
                      var set_format = {
                          'Date/Time': `${start_date} ${addTrailzero(start_time)}`
                      };
                      const time_start = moment(set_format['Date/Time'], "YYYY-MM-DD HH:mm");

                      let set_format_;
                      if(service_overnight === false){
                        set_format_ = {
                            'Date/Time': `${start_date} ${addTrailzero(end_time)}`
                        };
                      }else{
                        set_format_ = {
                            'Date/Time': `${end_date} ${addTrailzero(end_time)}`
                        };
                      }
                      const time_end = moment(set_format_['Date/Time'], "YYYY-MM-DD HH:mm");

                      const prime_difference = time_end.diff(time_start, "hours");

                      if(prime_difference >= 2){
                        setDisplayStart(time_start._d)
                        setDisplayEnd(time_end._d)
                        setCheckConfirm(true)
                      }else{
                        if(prime_difference >= 0){
                          handleBooking()
                        }
                        if(prime_difference < 0){
                          setDisplayStart(time_start._d)
                          setDisplayEnd(time_end._d)
                          setWrongDates(true)
                        }
                      }
                    }

            }else{
              setEndBeforeStart(true)
              console.log("End date can not be before start date")
            }
          }else{
            setFutureDates(true)
            console.log("Dates selected must be future dates...")
          }
        }else{

          if (start_date && start_time && end_date && end_time){
            var set_format = {
                'Date/Time': `${start_date} ${addTrailzero(start_time)}`
            };
            const time_start = moment(set_format['Date/Time'], "YYYY-MM-DD HH:mm");

            let set_format_;
            if(service_overnight === false){
              set_format_ = {
                  'Date/Time': `${start_date} ${addTrailzero(end_time)}`
              };
            }else{
              set_format_ = {
                  'Date/Time': `${end_date} ${addTrailzero(end_time)}`
              };
            }
            const time_end = moment(set_format_['Date/Time'], "YYYY-MM-DD HH:mm");

            const prime_difference = time_end.diff(time_start, "hours");

            if(prime_difference >= 2){
              setDisplayStart(time_start._d)
              setDisplayEnd(time_end._d)
              setCheckConfirm(true)
            }else{
              if(prime_difference >= 0){
                handleBooking()
              }
              if(prime_difference < 0){
                setDisplayStart(time_start._d)
                setDisplayEnd(time_end._d)
                setWrongDates(true)
              }
            }
          }

        }
      }
    }
  }
  
  const handleBooking = () => {
    setCheckConfirm(false)
    setWrongDates(false)
    if (service_id === null) {
      setNo_Service_Error(true);
      dispatch(createMessage('Please select a service.'));
      scroll.scrollTo(500);
      return;
    }
    const pets_array = JSON.parse(localStorage.getItem('pets_array'));
    // console.log(pets_array)
    if (pets_array.length === 0) {
      setNopetError(true);
      dispatch(createMessage('Please select or click on the add pet button below to continue booking.'));
      scroll.scrollToTop();
      return;
    }
    var bool = null;
    var bool_ = null;
    
    // console.log(props.auth.user.account_type)  // something to note are providers allowed to book ?
    const user_id = props.auth.user.id;

    const provider = props.detail !== null ? props.details?.first_name : "";
    if (props.auth.user.account_type === 'pet_owner') {
      if (bool_ === null && bool === null) {

        var set_format = {
          'Date/Time': `${start_date} ${start_time}`
        };
        const time_start = moment(set_format['Date/Time'], 'YYYY-MM-DD HH:mm').format('HH:mm:ss');
        const date_start = moment(set_format['Date/Time'], 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');

        var set_format2 = {
          'Date/Time': `${end_date} ${end_time}`
        };
        const time_end = moment(set_format2['Date/Time'], 'YYYY-MM-DD HH:mm').format('HH:mm:ss');
        const date_end = moment(set_format2['Date/Time'], 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');

        const today = new Date();
        const first = new Date(`${date_start}T${time_start}`);
        const second = new Date(`${date_end}T${time_end}`);

        
        if(localStorage.getItem("isMobile") === "false"){
        if (today <= first && today <= second) {
          //  console.log('Dates are correct');
          setNotCorrectDates(false);
          const rates_array = []
          const service = JSON.parse(localStorage.getItem('service_object'));
          // RATES
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          if(service){
            // Holiday Rate
              service.additional_rates.filter((rate) => {
                const rate_type = rate.rate_type.name;
                // -->  holiday rate
                var date_a = new Date().getDate();
                var month_a = new Date().getMonth() + 1;
                var year_a = new Date().getFullYear();
                var current_object = year_a + '-' + month_a + '-' + date_a;

                if (rate_type === 'Holiday Rate') {
                  const date = new Date(current_object)
                  console.log(date)
                  console.log(holidays.isHoliday(date))
                  if (holidays.isHoliday(date) === true) {
                    rates_array.push(rate.id);
                  }
                } 
              });
          }

          if (service && service?.additional_rates?.length > 0) {
              // Other rates
              const pets_arr = JSON.parse(localStorage.getItem('pets_array'));
              if(service.service.name === "Grooming" && pets_arr?.length > 1){
                
                const check_size_billing  = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                const check_size_billing2 = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                const check_size_billing3 = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")  
                
                // if user is not billing by size ie, doesn't have any special rates for billing
                // As long as user has any rate for gromming, go through all of them for each pet..
                if(check_size_billing.length !== 0 || check_size_billing2.length !== 0 || check_size_billing3.length !== 0){
                  const pets_data_array = []
                  // console.log(pets_arr)
                  for (let inde = 0; inde < pets_arr?.length; inde++) {
                    // console.log(pets_arr[inde])
                    const get_pet = props.pets?.results?.filter((pet) => pet.id === pets_arr[inde])
                    pets_data_array.push(get_pet)
                  }
                  for (let inde2 = 0; inde2 < pets_data_array?.length; inde2++) {
                      // console.log(pets_data_array[inde2][0])
                      const pet_size_ = (parseInt(pets_data_array[inde2][0]?.size))
                      if(pet_size_ <= 15){
                        const arate_typeGroom = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                        if(arate_typeGroom.length >= 1){
                          console.log(arate_typeGroom[0])
                          rates_array.push(arate_typeGroom[0]?.id);
                        }
                      }
                      if(pet_size_ >= 16 && pet_size_ <= 50){
                        const arate_typeGroom = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                        if(arate_typeGroom.length >= 1){
                          console.log(arate_typeGroom[0])
                          rates_array.push(arate_typeGroom[0]?.id);
                        }
                      }
                      if(pet_size_ > 50){
                        const arate_typeGroom = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
                        if(arate_typeGroom.length >= 1){
                          console.log(arate_typeGroom[0])
                          rates_array.push(arate_typeGroom[0]?.id);
                        }
                      }
                  }
                }
              }else{
                const selected_pet = props.pets?.results?.filter((pet) => pet.id === pets_arr[0])
                if(selected_pet.length >= 1 ){ 
                    // Dog size Rates
                    const pet_size = (parseInt(selected_pet[0]?.size))
                    if(pet_size <= 15){
                      const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                      if(arate_type.length >= 1){
                        // console.log(arate_type)
                        rates_array.push(arate_type[0]?.id);
                      }
                    }
                    if(pet_size >= 16 && pet_size <= 50){
                      const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                      if(arate_type.length >= 1){
                        // console.log(arate_type)
                        rates_array.push(arate_type[0]?.id);
                      }
                    }
                    if(pet_size > 50){
                      const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
                      if(arate_type.length >= 1){
                        // console.log(arate_type)
                        rates_array.push(arate_type[0]?.id);
                      }
                    }
                    
                    // Puppy Rate based on (dog)pet's age
                    const pet_age = (parseInt(selected_pet[0]?.age))
                    if(pet_age <= 2){
                      const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Puppy Rate")
                      if(arate_type.length >= 1){
                        // console.log(arate_type)
                        rates_array.push(arate_type[0]?.id);
                      }
                    }

                    // Over Milage Rate 
                    if(service.service.name === "Grooming"){
                      let distance = distance_between
                      if(distance > 10){
                        const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Over 10 miles")
                        if(arate_type.length >= 1){
                          // console.log(arate_type)
                          rates_array.push(arate_type[0]?.id);
                        }
                      }
                    }

                    // Additional Pet
                    if(service.service.name === "Grooming"){
                      if(pets_arr?.length > 1){

                        const check_size_billing  = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                        const check_size_billing2 = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                        const check_size_billing3 = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")  
                        
                        // if user is not billing by size ie, doesn't have any special rates for billing
                        if(check_size_billing.length === 0 && check_size_billing2.length === 0 && check_size_billing3.length === 0){
                          const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
                          // console.log(pets_arr)
                          // console.log(arate_type)
                          
                          if(arate_type?.length >= 1){
                            // rates_array.push(arate_type[0]?.id);
                            const pets_addition = pets_arr?.length - 1
                            for (let i = 0; i < pets_addition; i++) {
                              // console.log(arate_type)
                              rates_array.push(arate_type[0]?.id);
                            }
                          }

                        }

                      }
                    }else{
                      // Additional pet 
                      if(pets_arr?.length > 1){
                        // const additional_pet_length = pets_arr?.length - 1
                        const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
                        console.log(arate_type)
                        if(arate_type?.length >= 1){
                           rates_array.push(arate_type[0]?.id);
                        }
                      }
                  }  
                }
              } 
          }

          let end_date_var;
          if(service_overnight === false && recur === false){
            end_date_var = start_date
          }else{
            end_date_var = end_date
          }
          // console.log(start_time)
          // console.log(start_date)
          // console.log(end_time)
          // console.log(end_date_var)
          // console.log(recur)
          // console.log(interval)
          props.create_booking(
             start_time,
             start_date,
             end_time,
             end_date_var,
             notes,
             service_id,
             user_id,
             pets_array,
             provider,
             rates_array,
             recur,
             interval
          );
          setSubmitted(true);
          setOpenbutton(true)
        } else {
            setNotCorrectDates(true);
        }
      }else{
        setNotCorrectDates(false);
            const rates_array = []
            const service = JSON.parse(localStorage.getItem('service_object'));
            // var puppies_exist = localStorage.getItem('puppies_exist');
            // console.log(service)
            // console.log(puppies_exist)

            if(service){
              // Holiday Rate
                service.additional_rates.filter((rate) => {
                  const rate_type = rate.rate_type.name;
                  // -->  holiday rate
                  var date_a = new Date().getDate();
                  var month_a = new Date().getMonth() + 1;
                  var year_a = new Date().getFullYear();
                  var current_object = year_a + '-' + month_a + '-' + date_a;

                  if (rate_type === 'Holiday Rate') {
                    const date = new Date(current_object)
                    console.log(date)
                    console.log(holidays.isHoliday(date))
                    if (holidays.isHoliday(date) === true) {
                      rates_array.push(rate.id);
                    }
                  } 
                });
            }

            if (service && service?.additional_rates?.length > 0) {
              // Other rates
              const pets_arr = JSON.parse(localStorage.getItem('pets_array'));
              if(service.service.name === "Grooming" && pets_arr?.length > 1){
                
                const check_size_billing  = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                const check_size_billing2 = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                const check_size_billing3 = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")  
                
                // if user is not billing by size ie, doesn't have any special rates for billing
                if(check_size_billing.length !== 0 || check_size_billing2.length !== 0 || check_size_billing3.length !== 0){
                  const pets_data_array = []
                  // console.log(pets_arr)
                  for (let inde = 0; inde < pets_arr?.length; inde++) {
                    // console.log(pets_arr[inde])
                    const get_pet = props.pets?.results?.filter((pet) => pet.id === pets_arr[inde])
                    pets_data_array.push(get_pet)
                  }
                  for (let inde2 = 0; inde2 < pets_data_array?.length; inde2++) {
                      // console.log(pets_data_array[inde2][0])
                      const pet_size_ = (parseInt(pets_data_array[inde2][0]?.size))
                      if(pet_size_ <= 15){
                        const arate_typeGroom = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                        if(arate_typeGroom.length >= 1){
                          console.log(arate_typeGroom[0])
                          rates_array.push(arate_typeGroom[0]?.id);
                        }
                      }
                      if(pet_size_ >= 16 && pet_size_ <= 50){
                        const arate_typeGroom = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                        if(arate_typeGroom.length >= 1){
                          console.log(arate_typeGroom[0])
                          rates_array.push(arate_typeGroom[0]?.id);
                        }
                      }
                      if(pet_size_ > 50){
                        const arate_typeGroom = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
                        if(arate_typeGroom.length >= 1){
                          console.log(arate_typeGroom[0])
                          rates_array.push(arate_typeGroom[0]?.id);
                        }
                      }
                    
                  }

                }

            }else{
              const selected_pet = props.pets?.results?.filter((pet) => pet.id === pets_arr[0])
              if(selected_pet.length >= 1 ){ 
                  // Dog size Rates
                  const pet_size = (parseInt(selected_pet[0]?.size))
                  if(pet_size <= 15){
                    const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                    if(arate_type.length >= 1){
                      rates_array.push(arate_type[0]?.id);
                    }
                  }
                  if(pet_size >= 16 && pet_size <= 50){
                    const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                    if(arate_type.length >= 1){
                      rates_array.push(arate_type[0]?.id);
                    }
                  }
                  if(pet_size > 50){
                    const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
                    if(arate_type.length >= 1){
                      rates_array.push(arate_type[0]?.id);
                    }
                  }
                  
                  // Puppy Rate based on (dog)pet's age
                  const pet_age = (parseInt(selected_pet[0]?.age))
                  if(pet_age <= 2){
                    const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Puppy Rate")
                    if(arate_type.length >= 1){
                      rates_array.push(arate_type[0]?.id);
                    }
                  }

                  // Over Milage Rate 
                  if(service.service.name === "Grooming"){
                    let distance = distance_between
                    if(distance > 10){
                      const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Over 10 miles")
                      if(arate_type.length >= 1){
                        rates_array.push(arate_type[0]?.id);
                      }
                    }
                  }

                  // Additional Pet
                  if(service.service.name === "Grooming"){
                    if(pets_arr?.length > 1){

                      const check_size_billing  = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                      const check_size_billing2 = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                      const check_size_billing3 = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")  
                      
                      // if user is not billing by size ie, doesn't have any special rates for billing
                      if(check_size_billing.length === 0 && check_size_billing2.length === 0 && check_size_billing3.length === 0){
                        const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
                        console.log(pets_arr)
                        console.log(arate_type)
                        
                        if(arate_type?.length >= 1){
                          // rates_array.push(arate_type[0]?.id);
                          const pets_addition = pets_arr?.length - 1
                          for (let i = 0; i < pets_addition; i++) {
                            rates_array.push(arate_type[0]?.id);
                          }
                        }

                      }

                    }
                  }else{
                    // Additional pet 
                    if(pets_arr?.length > 1){
                      // const additional_pet_length = pets_arr?.length - 1
                      const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
                      console.log(arate_type)
                      rates_array.push(arate_type[0]?.id);
                    }
                }  
              }
            }  
          }

        console.log("Web Mobile")
        let end_date_var;
        if(service_overnight === false && recur === false){
          end_date_var = start_date
        }else{
          end_date_var = end_date
        }
        props.create_booking(
            start_time,
            start_date,
            end_time,
            end_date_var,
            notes,
            service_id,
            user_id,
            pets_array,
            provider,
            rates_array,
            recur,
            interval
          );
        setSubmitted(true);
        setOpenbutton(true)
       }
    }else {
      setBook_only(true);
      dispatch(createMessage('Booking is only for pet owners'));
      scroll.scrollToTop();
      return;
    }
  }
  }

  // eslint-disable-next-line no-unused-vars
  const redirect_signin = (e) => {
    window.location.href = '/login';
  };

  function get_currentdate() {
    var date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const return_date = year + '-' + month + '-' + day;
    return return_date;
  }
  function get_currenttime() {
    var dt = new Date();
    const formatted = `${dt.getHours()}:${dt.getMinutes()}`;
    const is_mobile = localStorage.getItem("isMobile")
    if(is_mobile === "true"){
      return formatted
    }
    var set_format = { 'Date/Time': ` 2018-09-30 ${formatted}` };
    const return_time = moment(set_format['Date/Time']).format('HH:mm:ss');
    return return_time;
  }

  const [notcorrectdates, setNotCorrectDates] = React.useState(false);
  const [start_time, setStart_time] = React.useState(get_currenttime());
  const [end_time, setEnd_time] = React.useState(get_currenttime());
  const [start_date, setStart_date] = React.useState(get_currentdate());
  const [end_date, setEnd_date] = React.useState(get_currentdate());
  const [notes, setNotes] = React.useState(null);
  const [startDateError, setStartDateError] = React.useState(false);
  const [EndDateError, setEndDateError] = React.useState(false);
  const [re_render, setAgain] = React.useState(false);
  const [pet_remove, setPetRemoved] = React.useState(false);

  // let set_init = new Date();
  // let set_init_date = `${set_init.getFullYear()}-${set_init.getMonth() + 1}-${set_init.getDate()}`;

  // new design
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [start_date_edit, setStart_date_edit] = useState(null);

  const [selectedDate_, setSelectedDate_] = useState(new Date());
  // const [end_date_edit, setEnd_date_edit] = useState(null);

  const [selectedTime, setSelectedTime] = useState(new Date());
  // const [start_time_edit, setStart_time_edit] = useState(null);

  const [selectedTime_, setSelectedTime_] = useState(new Date());
  // const [end_time_edit, setEnd_time_edit] = useState(null);

  const handleDateChange = (date) => {
    if(recur){
      const dayName = moment(date, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('dddd');
      console.log(dayName);
      setRecurringDayName(dayName)
    }
    setWrongDates(false)
    setEndBeforeStart(false)
    setFutureDates(false)
    setSameTimeError(false)
    setSelectedDate(date);
    let dt = new Date(date);
    let conDtObj = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    setStart_date(conDtObj);
    if(service_overnight === false){
      setEnd_date(conDtObj); // single day booking except overnight
    }
  };

  React.useEffect(() => {
    if(service_overnight){
      const currentDate = new Date();  // Get the current date
      const futureDate = new Date();   // Create a new Date object

      // Set the future date to be, for example, one day ahead of the current date
      futureDate.setDate(currentDate.getDate() + 1);

      handleDateChange_(futureDate)
    }
  }, [service_overnight])

  const handleDateChange_ = (date) => {
    setWrongDates(false)
    setEndBeforeStart(false)
    setFutureDates(false)
    setSameTimeError(false)
    setSelectedDate_(date);
    let dt = new Date(date);
    let conDtObj = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    setEnd_date(conDtObj);
  };

  const handleTimeChange = (date) => {
    setWrongDates(false)
    setEndBeforeStart(false)
    setFutureDates(false)
    setSameTimeError(false)
    setSelectedTime(date);

    let dt = new Date(date);
    let conDtObj = `${dt.getHours()}:${dt.getMinutes()}`;
    setStart_time(conDtObj);

  };

  const handleTimeChange_ = (date) => {
    setWrongDates(false)
    setEndBeforeStart(false)
    setFutureDates(false)
    setSameTimeError(false)
    setSelectedTime_(date);
    let dt = new Date(date);
    let conDtObj = `${dt.getHours()}:${dt.getMinutes()}`;
    setEnd_time(conDtObj);

  };

  React.useEffect(() => {
    if (props.messages.notify_timeout && submitted === true) {
      props.go_to_next_step()
    }
  }, [props.messages.notify_timeout, submitted])


  if (!props.auth.token) {
    console.log('User is not authenticated');
    return (
      <Fragment>
        <Redirect />
      </Fragment>
    );
  }

  let alert;
  if (props.messages.notify_timeout !== null) {
    alert = <div className="alerts">{props.messages.notify_timeout}</div>;
  }

  const get_new = (date) => {
    handleDateChange(date)
    handleDateChange_(date)
    scroll.scrollMore(100);
  }
  // console.log("props.details")
  // console.log(props.details)

  function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}  
  
  
  const pet_added = (value) => {
    
    if(value === true){
      // console.log("pet_added")
      setAgain(true)
    }else{
      // console.log("pet_removed")
      setPetRemoved(true)
      setAgain(false)
    }
  }

  return (
    <div className={classes.root}>
      <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
      <div>{!props.auth.token && <GuestNavBar />}</div>
      {props.details !== null ? (
        <Container className={classes.wizardContainer}>
          <Paper elevation={1} className={classes.paper}>
            <div className={classes.page}>
              <div className={classes.pageTitle}>Book {props.details?.first_name}</div>
              {props.messages.notify_timeout && noPets === true ? (
                    <Grid item xs={12}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              props.clear_error();
                              setNopetError(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <div className={classes.message}>{alert}</div>
                      </Alert>
                    </Grid>
                  ) : null}
                  {props.auth.user !== null ? (
                    <span>
                      {props.pets ? (
                        <AddPetsBooking pet_added={pet_added}/>
                      ) : (
                        <div
                          style={{
                            marginLeft: '30px',
                            marginBottom: '10px'
                          }}
                        >
                          <img src={Spinner} alt="" height="40px" width="40px" />
                        </div>
                      )}
                    </span>
                  ) : null}
              {(props.messages.notify_timeout && noService === true) ||
                (props.messages.notify_timeout && book_only_owner === true) ? (
                <Grid item xs={12}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          props.clear_error();
                          setNo_Service_Error(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <div className={classes.message}>{alert}</div>
                  </Alert>
                </Grid>
              ) : null}
              <div className={classes.contant}>
                <div className={classes.services}>
                  <div className={classes.title}>Services</div>
                  
                  <div className={classes.servicesWraper}>
                    <div className={classes.root_}>
                      <Grid container item xs={12} spacing={3} style={{ marginBottom: '20px' }}>
                        {!isEmpty(props.details?.services)
                          ? props?.details?.services.map((service, idx) => (
                           
                            <DetailServiceBooking 
                              getService={getService}
                              service={service}
                              pets={props?.pets}
                              service_id={service_id}
                              re_render={re_render}
                              setAgain={setAgain}
                              setPetRemoved={setPetRemoved}
                              pet_remove={pet_remove}
                              label={`${service.service.name} at $${service.price} ${service.rate.name}`}
                              image={service.service.icon}
                              has_additional_rates={service?.additional_rates?.length > 0}
                            /> 
                          ))
                          : null}
                      </Grid>
                    </div>
                  </div>

                  {/*
                    localStorage.getItem("isMobile") === "false" ?
                      <div className={classes.reviews}>
                        <div className={classes.title_}>Availability</div>
                        <div className={classes.pageTitle}>
                          This calendar shows the dates when {props.details.first_name} is available.
                        </div>
                        <div className={classes.pageTitle_}>
                          Please double click on the available date to book your service.
                        </div>
                        {data && props.form.set_view_appointments_ready ? (
                          <div className={classes.calendar}>
                            <AppointmentsBook
                              data={data}
                              current={current}
                              fetch={fetching}
                              get_new={get_new}
                            />
                          </div>
                        ) : (
                          <div>
                            {props.auth.isAuthenticated ? (
                              <img src={Spinner} alt="" height="80px" width="80px" />
                            ) : (
                              <div className={classes.title__}>
                                Please{' '}
                                <Link variant="h6" color="primary" onClick={redirect_signin}>
                                  sign in
                                </Link>{' '}
                                to view availability
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    : null */}

                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className={classes.content}>
                        <Typography>
                          <span className={classes.titleBlock}>
                            Please add the following details about your booking.
                          </span>
                          <br />
                          
                          {notcorrectdates === true ? (
                            <FormLabel
                              component="label"
                              className={classes.formLabel_}
                            //style={{ marginLeft: '20px' }}
                            >Please book a new appointment as this one is already past.
                            </FormLabel>
                          ) : null}
                          {endBeforeStart === true ? (
                            <FormLabel
                              component="label"
                              className={classes.formLabel_}
                            //style={{ marginLeft: '20px' }}
                            >End date can not be before start date.
                            </FormLabel>
                          ) : null}
                          {futureDates === true ? (
                            <FormLabel
                              component="label"
                              className={classes.formLabel_}
                            //style={{ marginLeft: '20px' }}
                            >Please select future dates to continue.
                            </FormLabel>
                          ) : null}
                          {dateTimeSame === true ? (
                            <FormLabel
                              component="label"
                              className={classes.formLabel_}
                            //style={{ marginLeft: '20px' }}
                            >Booking start time and end time can not be the same.
                            </FormLabel>
                          ) : null}
                        </Typography>
                        <div className={classes.wrapper}>
                          <Grid item xs={12} sm={6} style={{ marginRight: '10px' }}>
                            <FormLabel component="label" className={classes.formLabel}>
                              Start time
                            </FormLabel>
                            <ThemeProvider theme={theme}>
                              <KeyboardTimePicker
                                keyboardIcon={<AccessAlarmIcon />}
                                className={classes.line}
                                InputProps={{ classes: { underline: classes.underline } }}
                                label=""
                                placeholder="Start time AM"
                                mask="__:__ _M"
                                value={selectedTime}
                                onChange={(date) => handleTimeChange(date)}
                              />
                            </ThemeProvider>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormLabel component="label" className={classes.formLabel}>
                              End time
                            </FormLabel>
                            <ThemeProvider theme={theme}>
                              <KeyboardTimePicker
                                keyboardIcon={<AccessAlarmIcon />}
                                className={classes.line}
                                InputProps={{ classes: { underline: classes.underline } }}
                                label=""
                                placeholder="Start time AM"
                                mask="__:__ _M"
                                value={selectedTime_}
                                onChange={(date) => handleTimeChange_(date)}
                              />
                            </ThemeProvider>
                          </Grid>
                        </div>
                        <div className={classes.wrapper}>
                          <Grid item xs={12} sm={(service_overnight || recur) ? 6 : 12} style={{ marginRight: '10px' }}>
                            {recur ? 
                              <FormLabel component="label" className={classes.formLabel}>
                                Recurring day 
                                <FormLabel component="label" className={classes.formLabe}>{
                                  recurringDayName !== null ? `: ${recurringDayName}` : null
                                }</FormLabel>
                              </FormLabel> : 
                              <FormLabel component="label" className={classes.formLabel}>
                                When would you like to start?
                              </FormLabel>
                            }
                            <KeyboardDatePicker
                              className={classes.line}
                              InputProps={{ classes: { underline: classes.underline } }}
                              placeholder="08:00 AM"
                              id="date-picker-dialog"
                              format="MM/dd/yyyy"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />
                            {startDateError === true ? (
                              <FormLabel component="label" className={classes.formLabel_}>
                                Please select a date and time when {props.details.first_name} is
                                available
                              </FormLabel>
                            ) : null}
                          </Grid>
                          {(service_overnight || recur) ? 
                            <Grid disabled={true} item xs={12} sm={(service_overnight || recur) ? 6 : 12}>
                              <FormLabel component="label" className={classes.formLabel}>
                                When would you like to end?
                              </FormLabel>
                              <KeyboardDatePicker
                                className={classes.line}
                                InputProps={{ classes: { underline: classes.underline } }}
                                placeholder="08:00 AM"
                                // disableUnderline={true}
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                value={selectedDate_}
                                onChange={handleDateChange_}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                              />
                              {EndDateError === true ? (
                                <FormLabel component="label" className={classes.formLabel_}>
                                  Please select a date and time when {props.details.first_name} is
                                  available
                                </FormLabel>
                              ) : null}
                            </Grid> : null}
                        </div>
                        {/* Recurring code */}
                        <div className={classes.wrapper}>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.wrapper2}>
                              <LoopIcon className={classes.icon} color="action" />
                              <Grid item xs={6} sm={6}>
                                <FormLabel component="label" className={classes.formLabel}>
                                  Recurring 
                                </FormLabel>
                                <FormGroup className={classes.formGroup__}>
                                  <FormControlLabel
                                    control={<Checkbox
                                      checked={recur}
                                      onChange={handleRecur}
                                      name="small" />}
                                    label="Yes"
                                  />
                                </FormGroup>
                              </Grid>
                            </div>
                          </Grid>
                          {recur ? <Grid item xs={12} sm={6}>
                            <div className={classes.wrapper2}>
                              <TimelineIcon className={classes.icon} color="action" />
                              <Grid item xs={6} sm={6}>
                                <FormLabel component="label" className={classes.formLabel}>
                                  Recurring Interval
                                </FormLabel>
                                <Select
                                  onChange={(e) => setInterval_(e.target.value)}
                                  disableUnderline
                                  displayEmpty
                                  fullWidth
                                  value={interval}
                                  // defaultValue={props.schedules.occurrence}
                                  className={classes.inputSmall}
                                  inputProps={{
                                    'aria-label': 'Select Expiry year'
                                  }}
                                >
                                  {/*<MenuItem value="none">None</MenuItem>*/}
                                  <MenuItem value="daily">Daily</MenuItem>
                                  {/*<MenuItem value="biweekly">Bi-weekly</MenuItem>*/}
                                  <MenuItem value="weekly">Weekly</MenuItem>
                                  <MenuItem value="monthly">Monthly</MenuItem>
                                  {/*<MenuItem value="annually">Annually</MenuItem>*/}
                                </Select>
                              </Grid>
                            </div>
                          </Grid>: null}
                        </div>


                        <div className={classes.wrapper}>
                          
                          <Grid item xs={12}>
                          {/*<Notes className={classes.icon} color="action" />*/}
                            <FormLabel component="label" className={classes.formLabel}>
                              Message
                            </FormLabel>
                            <Input
                              fullWidth
                              id="notes"
                              name="notes"
                              disableUnderline
                              onChange={(e) => setNotes(e.target.value)}
                              value={notes}
                              multiline
                              placeholder="Share any important notes about your pet or the service you are requesting."
                              rows="4"
                              className={classes.inputSmall}
                            />
                          </Grid>
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>

                    {props.messages.notify_timeout && submitted === true ? (
                      <Grid item md={12} sm={12} xs={12}>
                        <Alert
                          severity="info"
                          // style={{ marginLeft: '60px' }}
                          icon={false}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                props.clear_error();
                                props.make_booking_first_signal_reset();
                                setSubmitted(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          <div className={classes.message}>{alert}</div>
                        </Alert>
                      </Grid>
                    ) : null}

                    {wrong_dates ? 
                      <Alert
                          severity="error"
                          // style={{ marginLeft: '60px' }}
                          icon={false}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setWrongDates(false)
                                setDisplayStart(null)
                                setDisplayEnd(null)
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                        <div className={classes.message}>
                          {"You're not able to book from "} 
                          <span style={{ fontWeight: 'bold' }}>{`${moment(display_start, 'DD-MMM hh:mm').format('DD-MMM hh:mm A')}`}</span>
                           {" to "} 
                          <span style={{ fontWeight: 'bold' }}> {`${moment(moment(display_end, 'DD-MMM hh:mm').toDate()).format('DD-MMM hh:mm A')}`}</span>.
                          {" "}{"Did you mean to enter "}
                          <span style={{ fontWeight: 'bold' }}> {`${moment(moment(display_end, 'DD-MMM hh:mm').toDate()).format('DD-MMM hh:mm A').toString().substring(0, 12)}`} PM</span>
                        </div>
                        </Alert>: null}
                    

                    {check_confirm ? 
                     <Grid item md={12} sm={12} xs={12}>
                        <Alert
                          severity="info"
                          // style={{ marginLeft: '60px' }}
                          icon={false}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setCheckConfirm(false)
                                setDisplayStart(null)
                                setDisplayEnd(null)
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                        {
                          recur ? 
                          <div className={classes.message}>
                            {"Please confirm that you want to book this recurring appointment; "} 
                            <span style={{ fontWeight: 'bold' }}>{`${moment(display_start, 'DD-MMM hh:mm').format('DD-MMM')}`} - {`${moment(moment(display_end, 'DD-MMM hh:mm').toDate()).format('DD-MMM')}`}, </span>
                             {" from "} 
                            <span style={{ fontWeight: 'bold' }}>{`${moment(moment(display_start, 'DD-MMM hh:mm').toDate()).format('hh:mm A')}`} - {`${moment(moment(display_end, 'DD-MMM hh:mm').toDate()).format('hh:mm A')}`}</span>
                          </div>: 
                          <div className={classes.message}>
                            {"Please confirm that you want to book this appointment from "} 
                            <span style={{ fontWeight: 'bold' }}>{`${moment(display_start, 'DD-MMM hh:mm').format('DD-MMM hh:mm A')}`}</span>
                             {" to "} 
                            <span style={{ fontWeight: 'bold' }}>{`${moment(moment(display_end, 'DD-MMM hh:mm').toDate()).format('DD-MMM hh:mm A')}`}</span>
                          </div>
                        }

                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            style={{ marginRight: '30px' }}
                            // onClick={check_time_func}
                            onClick={handleBooking}
                          >
                            <span>{'Confirm'}</span>
                          </Button>
                        </div>
                          
                        </Alert>
                      </Grid>: null}


                    <div className={classes.buttonGroup}>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{
                          maxWidth: '200px',
                          maxHeight: '35px',
                          minWidth: '200px',
                          minHeight: '35px'
                        }}
                        className={open_button ? classes.button_disabled : classes.button}
                        disabled={open_button}
                        onClick={check_time_func}
                        // onClick={handleBooking}
                      >
                        {open_button ?
                          <span style={{ color: "#899499" }} >{'Submit booking'}{' '}</span> :
                          <span>{'Submit booking'}{' '}</span>
                        }
                        {props.booking.isLoading === true ? (
                          <div
                            style={{
                              marginLeft: '10px',
                              marginTop: '5px'
                            }}
                          >
                            <img src={Spinner} alt="" height="25px" width="25px" />
                          </div>
                        ) : null}
                      </Button>
                    </div>
                  </div>
                </div>
                {/*<div className={classes.contantInfo}>
                  <div className={classes.about}>
                    <div className={classes.title}>About</div>
                    <div className={classes.description}>
                      {props.description}
                    </div>
                    {/*<div>
                      <PetGallery/>
                    </div>}
                  </div>
                  <div style={{ 'marginBottom' : '30px'}}>
                  </div>
                  </div>*/}
              </div>
            </div>
          </Paper>
        </Container>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '73px',
            background: '#fff'
          }}
        >
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              style={{
                position: 'absolute',
                //bottom: '-11px',
                // right: '-28%',
                // zIndex: -1,
                marginBottom: '41px',
                marginRight: '31px'
              }}
              src={Footprint}
              alt=""
            />
            <img src={Spinner} alt="" height="100px" width="100px" />
          </div>
        </div>
      )}
      {/* <Footer/>*/}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pets: state.pets.pets,
  form: state.forms,
  booking: state.booking,
  messages: state.messages,
  availability: state.forms.availability_data,
  availability_data_next: state.forms.availability_data_next,
  details: state.results.petproviderdetails,
  payment_methods: state.services.payment_methods
});

export default connect(mapStateToProps, {
  get_availability_data,
  clear_aval,
  getproviderdetails,
  loadUser,
  getPetData,
  get_payment_method,
  clear_error,
  make_booking_first_signal_reset,
  createMessage,
  create_booking,
  setToNull
})(PetProviderInfo);

