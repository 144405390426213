import React from "react";
import { connect } from "react-redux";
import PetProvider from "../views/PetProvider";
import PetProviderCharges from "../views/PetProviderCharges";
import BookingPaymentList from "../components/BookingPaymentList";

import PaymentMethodList from "../components/PaymentMethodListBook";

import { loadUser } from "../actions/auth.js";
import { reset_go_to_next, get_payment_method } from "../actions/form.js";

import * as Scroll from "react-scroll";

var scroll = Scroll.animateScroll;

function DashboardWizardSteps(props) {
  React.useEffect(() => {
    props.loadUser();
    props.get_payment_method();
    props.get_payment_method();
    props.loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(props.card_created){
      props.get_payment_method();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.card_created]);

  const fetch_again = async () => {
    console.log("fetch_again: Fetching payments again")
    props.get_payment_method();
    props.get_payment_method();
  };

  const go_to_next_step = () => {
    localStorage.setItem(`booking_status_${props.user.id}`, "1");
    props.setActiveStep(1);
  }

  const current_stage = props.step;

  React.useEffect(() => {
    props.reset_go_to_next(); // making sure this is always reset to false
    scroll.scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_stage]);

  if (current_stage === "0") {
    if (props.payment_methods) {
      props.setActiveStep(0);
      return (
        <div>
          <PetProvider 
             payment_methods={props.payment_methods} 
             id={props.id}
             go_to_next_step={go_to_next_step}
          />
        </div>
      );
    }
  } else if (current_stage === 0) {
    if (props.payment_methods) {
      props.setActiveStep(0);
      return (
        <div>
          <PetProvider 
             payment_methods={props.payment_methods} 
             id={props.id}
             go_to_next_step={go_to_next_step}
          />
        </div>
      );
    }
  } else if (current_stage === "1") {
    props.setActiveStep(1);
    return <div>
          <BookingPaymentList
            payment_methods={props.payment_methods}
            fetch_again={fetch_again}
          />
          <PaymentMethodList
            payment_methods={props.payment_methods}
            fetch_again={fetch_again}
          />
       </div>;
  } else if (current_stage === 1) {
    props.setActiveStep(1);
    return <div>
          <BookingPaymentList
            payment_methods={props.payment_methods}
            fetch_again={fetch_again}
          />
          <PaymentMethodList
            payment_methods={props.payment_methods}
            fetch_again={fetch_again}
          />
       </div>;
  } else if (current_stage === "2") {
    props.setActiveStep(2);
    return <PetProviderCharges />;
  } else if (current_stage === 2) {
    props.setActiveStep(2);
    return <PetProviderCharges />;
  } else {
    props.setActiveStep(0);
    return (
      <div>
          <PetProvider payment_methods={props.payment_methods} id={props.id}/>
        </div>
    );
  }

  props.setActiveStep(0);
  return (
    <div>
        <PetProvider payment_methods={props.payment_methods} id={props.id}/>
      </div>
  );
}

const mapStateToProps = (state) => ({
  form: state.forms,
  user: state.auth.user,
  results: state.services.results,
  card_created: state.services?.card_created,
  all_services: state.services.admin_services,
  payment_methods: state.services.payment_methods,
});
export default connect(mapStateToProps, {
  loadUser,
  reset_go_to_next,
  get_payment_method,
})(DashboardWizardSteps);
