/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

// import Avatar from "../assets/avatar-01.png";
import GuestNavBar from '../components/GuestNavBar';
import PrimarySearchAppBar from '../components/AuthorizedUserHomepageNavbar';
import { loadUser } from '../actions/auth.js';
import Paper from "@material-ui/core/Paper";
import { get_availability_data, clear_aval, getPetData, clear_error } from '../actions/form.js';
import { create_booking } from '../actions/booking.js';
import { getproviderdetails } from '../actions/results';
import Redirect from '../views/Redirect.js';
import Spinner from '../assets/home_load.gif';
import Footprint from '../assets/footprint.svg';
import logo from '../assets/logo.svg';
import { createMessage } from '../actions/messages';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';

import holidays from '@date/holidays-us';
// import * as Scroll from 'react-scroll';
// var scroll = Scroll.animateScroll;

// var holidays = require('@date/holidays-us')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    paddingTop: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.down("550")]: {
      margin: '0 !important',
    },
    // background: '#D4F2F2'
  },
  root_: {
    flexGrow: 1
  },
  page: {
    // padding: '45px 92px 44px 107px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    borderColor: 'black',
    background: 'white',
    shadowColor: "#000",
    shadowOffset: { width: -2, height: 4},
    shadowOpacity: 0.3,
    shadowRadius: 2.62,

    minHeight: '400px',
    borderRadius: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('768')]: {
      maxWidth: '375px',
      margin: '0 auto',
      padding: '45px 20px 44px 20px'
    }
  },
  head: {
    marginBottom: '48px',
    display: 'flex',
    alignItems: 'flex-start'
  },
  avatar: {
    marginRight: '95px',
    width: '184px',
    maxWidth: '184px',
    textAlign: 'center',
    [theme.breakpoints.down('768')]: {
      width: '140px',
      maxWidth: '140px',
      marginRight: '30px'
    }
  },
  avatarImg: {
    width: '184px',
    maxWidth: '184px',
    height: '184px',
    maxHeight: '184px',
    [theme.breakpoints.down('768')]: {
      width: '140px',
      maxWidth: '140px',
      height: '100%'
    },
    '& > img': {
      width: '100%'
    }
  },
  numberStar: {
    marginLeft: '7px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#000000'
  },
  starRatings: {
    marginTop: '25px',
    marginLeft: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('768')]: {
      marginTop: '0px',
      position: 'relative',
      left: '90px',
      top: '10px'
    }
  },
  headInfo: {
    textAlign: 'left',
    width: '100%'
  },
  headName: {
    marginTop: '20px',
    maxWidth: 'fit-content',
    marginBottom: '8px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
    color: '#222222',
    position: 'relative',
    [theme.breakpoints.down('768')]: {
      marginTop: 0,
      marginBottom: '12px',
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  background_check: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'grey',
    fontSize: '55px'
  },
  headName_: {
    marginTop: '5px',
    maxWidth: 'fit-content',
    marginBottom: '5px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
    color: '#222222',
    position: 'relative',
    [theme.breakpoints.down('768')]: {
      marginTop: 0,
      marginBottom: '4px',
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  headName__: {
    marginTop: '10px',
    maxWidth: 'fit-content',
    marginBottom: '6px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
    color: '#222222',
    position: 'relative',
    [theme.breakpoints.down('768')]: {
      marginTop: 0,
      marginBottom: '4px',
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  headMark: {
    position: 'absolute',
    right: '-26px',
    top: '0',
    [theme.breakpoints.down('768')]: {
      right: '-26px',
      left: 'auto'
    }
  },
  headLocation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '20px'
  },
  headLocationImg: {
    marginRight: '20px'
  },
  headLocationTitle: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    textDecoration: "none",
    color: '#383F45'
  },
  headLocationSubtitle: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#4F4F4F',
    marginBottom: '30px'
  },
  headLocationBtn: {
    marginTop: '14px',
    width: '149px',
    height: '41px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#156981',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background 0.4s',
    [theme.breakpoints.down('768')]: {
      marginTop: '12px',
      fontSize: '20px',
      height: '36px'
    },
    '&:hover': {
      background: '#88CCDD'
    }
  },
  title: {
    marginBottom: '5px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    textAlign: "left",
    marginLeft: "10px",
    color: '#222222',
    [theme.breakpoints.down('768')]: {
      marginBottom: '5px',
      fontSize: '18px',
      // lineHeight: '24px'
    }
  },
  title_: {
    maxWidth: '624px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '350',
    fontSize: '32px',
    lineHeight: '58px',
    color: '#23286B',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    },
  },
  title__: {
    maxWidth: '624px',
    marginBottom: '9px',
    alignItems: 'center',
    fontWeight: '350',
    fontSize: '18px',
    lineHeight: '58px',
    color: 'black',
    [theme.breakpoints.down('1210')]: {
      maxWidth: '506px'
    },
    [theme.breakpoints.down('700')]: {
      maxWidth: '327px',
      fontSize: '26px',
      lineHeight: '32.86px'
    }
  },
  description: {
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#383F45',
    [theme.breakpoints.down('768')]: {
      fontSize: '14px',
      lineHeight: '18px'
    }
  },
  services: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  constant: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: '100%'
  },
  servicesWraper: {
    // width: '1071px',
    display: 'flex',
    marginBottom: '20px',
    // flexWrap: 'wrap',
    // flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down("700")]: {
      '& > div': {
        width: '100%'
      },
    },
    // [theme.breakpoints.down("1110")]: {
    //   width: '100%',
    // },
    // [theme.breakpoints.down("950")]: {
    //   flexDirection: 'column'
    // },
  },
  servicesItem: {
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    // padding: '11px 0px 8px 0px',
    // width: 'calc((100% - 40px) / 2)',
    display: 'flex',
    height: '150px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#F2F2F2',
    border: '2px solid #E0E0E0',
    borderRadius: '10px',
    '&:hover': {
      borderColor: 'blue'
    }
    // [theme.breakpoints.down("1110")]: {
    //   width: 'calc((100% - 20px) / 2)',
    // },
    // [theme.breakpoints.down("950")]: {
    //   width: '100%',
    // },
    // [theme.breakpoints.down("768")]: {
    //   padding: '5px 0px 5px 0px',
    //   marginBottom: '8px',
    // },
  },
  servicesTitle: {
    marginBottom: '4px',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '40px',
    color: '#1B5E20',
    [theme.breakpoints.down('768')]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  line: {
    background: "black",
    height: "1px",
    // marginTop: "5px",
    width: '100%'
  },
  servicesTitle_: {
    marginBottom: '4px',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '40px',
    color: '#1B5E20',
    [theme.breakpoints.down('768')]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  servicesPrice: {
    marginBottom: '13px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#000000',
    [theme.breakpoints.down('768')]: {
      marginBottom: '5px'
    }
  },
  servicesBtn: {
    width: '91px',
    height: '25px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#156981',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'background 0.4s',
    [theme.breakpoints.down('768')]: {
      width: '70px',
      height: '20px'
    },
    '&:hover': {
      background: '#88CCDD'
    }
  },
  contant: {
    // display: 'flex',
    // alignItems: 'flex-start',
    // justifyContent: 'space-between',
    // [theme.breakpoints.down("1110")]: {
    //   flexDirection: 'row-reverse'
    // },
    // [theme.breakpoints.down("950")]: {
    //   maxWidth: '680px',
    //   margin: '0 auto'
    // },
  },
  contantInfo: {
    width: '100%',
    maxWidth: '564px',
    [theme.breakpoints.down('1250')]: {
      maxWidth: '50%'
    },
    [theme.breakpoints.down('950')]: {
      maxWidth: '500px',
      marginRight: '20px'
    }
  },
  about: {
    //  marginBottom: '76px',
    maxWidth: '444px',
    [theme.breakpoints.down('950')]: {
      marginBottom: '30px'
    }
  },
  gallery: {
    marginBottom: '106px',
    maxWidth: '444px',
    [theme.breakpoints.down('950')]: {
      marginBottom: '30px'
    }
  },
  post: {
    marginBottom: '34px',
    '&:last-child': {
      marginBottom: '28px'
    }
  },
  postHead: {
    marginBottom: '14px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  postAvatar: {
    marginRight: '17px',
    maxHeight: '53px',
    maxWidth: '53px'
  },
  postAvatarImg: {
    width: '100%'
  },
  rating: {
    marginLeft: 'auto'
  },
  postHeadName: {
    marginBottom: '7px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#383F45'
  },
  buttonAside: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#828282',
    alignItems: 'center',
    justifyContent: "center",
  },
  postText: {
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#383F45',
    [theme.breakpoints.down('768')]: {
      fontSize: '14px',
      lineHeight: '18px'
    }
  },
  postsMore: {
    display: 'block',
    margin: '0 auto',
    width: '87px',
    height: '24px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#BDBDBD',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer'
  },
  imageSelector: {
    fontSize: '8rem',
    width: theme.spacing(26),
    height: theme.spacing(26),
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  reviews: {
    marginTop: '10px',
    marginBottom: '40px'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    // padding: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    verticalAlign: "middle",
    marginBottom: "15px",
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
    maxWidth: '80%'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0)
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2)
  },
  formLabel: {
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '600',
    marginBottom: theme.spacing(1)
  },
  inputSmall: {
    fontSize: '13px',
    color: '#1b1f23',
    border: '1px solid #cfd7de',
    borderRadius: '5px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    // marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    '&::after': {
      borderBottom: '1px solid #949494'
    }
  },
  pageTitle: {
    maxWidth: '624px',
    marginBottom: '2px',
    // alignItems: 'center',
    fontWeight: '200',
    fontSize: '22px',
    lineHeight: '58px',
    color: '#23286B'
  },
  button: {
    marginLeft: '60px',
    marginTop: '10px',
    alignItems: 'center',
    color: '#FF3D00',
    justifyContent: 'center',
    backgroundColor: '#fff!important',
    border: '1.5px solid #FF3D00',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#F0FFF0!important'
    }
  },
  // headMark: {
  //   position: 'absolute',
  //   right: '-26px',
  //   top: '0',
  //   [theme.breakpoints.down('768')]: {
  //     right: '-26px',
  //     left: 'auto'
  //   }
  // },
  titleBlock: {
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    marginTop: "25px",
    marginBottom: "20px",
    textAlign: "center",
    fontSize: '18px',
    color: 'black',
  },

  //   root: {
  //   paddingBottom: '10px',
  //   width: '100%',
  //   minHeight: '100vh',
  //   paddingTop: '73px',
  //   background: "#D4F2F2",

  // },
  // page: {
  //   margin: "40px 0",
  //   padding: '76px 0px 0px 0px',
  //   width: '100%',
  //   background: 'white',
  //   minHeight: '400px',
  //   borderRadius: '10px',
  //   overflow: 'hidden',
  //   [theme.breakpoints.down("680")]: {
  //     padding: '50px 0px 0px 0px',
  //     maxWidth: '400px',
  //     margin: '0 auto',
  //     marginTop: "20px",
  //     marginBottom: "20px",
  //   },
  // },
  custom: {
    margin: '0',
    padding: '18px 16px 14px',
    maxWidth: '210px',
    minHeight: '56px',
    border: '1px solid rgba(25, 25, 25, 0.32)',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('680')]: {
      marginBottom: '10px',
      maxWidth: '300px',
      width: '300px'
    },
    '& > .MuiInput-underline': {
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    },
    '& > .MuiPickersToolbar': {
      toolbar: 'none'
    }
  },
  toolbar: {
    backgroundColor: 'red'
  },
  // pageTitle: {
  //   marginBottom: '68px',
  //   fontFamily: 'Dosis',
  //   fontStyle: 'normal',
  //   fontWeight: '600',
  //   fontSize: '36px',
  //   lineHeight: '36px',
  //   color: '#222222',
  //   [theme.breakpoints.down("680")]: {
  //     marginBottom: '40px',
  //     fontSize: '28px',
  //     lineHeight: '28px',
  //   },
  // },
  // contant: {
  //   maxWidth: "1024px",
  //   marginBottom: '48px',
  //   textAlign: 'center',
  //   padding: '0 52px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   [theme.breakpoints.down("680")]: {
  //     padding: '0 15px'
  //   },
  // },
  input: {
    display: 'none'
  },
  inputLabel: {
    padding: '10px 22px 10px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '495px',
    height: '93px',
    background: '#E7E7E7',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  inputText: {
    marginLeft: '34px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    order: '1',
    [theme.breakpoints.down('680')]: {
      marginLeft: '15px'
    }
  },
  petName: {
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '29px',
    lineHeight: '36px',
    color: '#156981',
    [theme.breakpoints.down('680')]: {
      fontSize: '22px'
    }
  },
  petType: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#68AC82'
  },
  circle: {
    marginLeft: 'auto',
    width: '32px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #4F4F4F',
    borderRadius: '50%',
    position: 'relative',
    display: 'block',
    order: '2',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '18px',
      height: '7px',
      top: '8px',
      right: '4px',
      display: 'block'
    }
  },
  formgroup: {
    marginBottom: '63px',
    maxWidth: '600px',
    margin: '0 auto',
    [theme.breakpoints.down('1030')]: {
      maxWidth: '450px'
    },
    [theme.breakpoints.down('680')]: {
      marginBottom: '40px'
    }
  },
  grid: {
    marginBottom: '43px',
    [theme.breakpoints.down('680')]: {
      marginBottom: '20px'
    }
  },
  buttonForm: {
    marginTop: '36px',
    marginBottom: '38px',
    display: 'block',
    width: '100%',
    padding: '16.5px 0',
    background: '#fff',
    borderRadius: '10px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '24px',
    margin: '0 auto',
    outline: 'none',
    cursor: 'pointer',
    border: '1px solid #BDBDBD',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#156981',
    transition: 'background 0.4s',
    [theme.breakpoints.down('680')]: {
      marginTop: '20px',
      marginBottom: '40px'
    },
    '&:hover': {
      backgroundColor: '#cce5e7'
    }
  },
  submit: {
    display: 'block',
    height: '39px',
    width: '141px',
    background: '#156981',
    borderRadius: '10px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '21px',
    lineHeight: '27px',
    color: '#FFFFFF',
    margin: '0 auto',
    marginTop: '36px',
    outline: 'none',
    cursor: 'pointer',
    border: '1px solid #BDBDBD',
    textAlign: 'center',
    letterSpacing: '0.15px',
    transition: 'background 0.4s',
    '&:hover': {
      backgroundColor: '#52a3bb'
    }
  },
  // titleBlock: {
  //   marginBottom: '30px',
  //   fontFamily: 'Dosis',
  //   fontStyle: 'normal',
  //   fontWeight: '600',
  //   fontSize: '30px',
  //   lineHeight: '30px',
  //   color: '#000000',
  //   textAlign: 'left',
  //   letterSpacing: '0.15px',
  //   [theme.breakpoints.down('680')]: {
  //     marginBottom: '20px',
  //     fontSize: '24px',
  //     lineHeight: '24px'
  //   }
  // },
  textarea: {
    width: '100%',
    // maxWidth: '600px',
    margin: '0 auto',
    height: '71px',
    padding: '8px 22px 8px 14px',
    background: 'white',
    borderRadius: '4px 4px 0px 0px',
    marginBottom: "15px",
    borderColor: 'solid 2px #00BCD4',
    fontFamily: 'Averia Sans Libre',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '22px',
    color: 'black',
    resize: 'none',
    outline: 'none',
    [theme.breakpoints.down('680')]: {
      height: 'auto'
    }
  },
  form: {
    width: '100%'
  },
  imgWrap: {
    maxWidth: '73px',
    maxHeight: '73px',
    order: '0',
    '& > img': {
      width: '100%'
    },
    [theme.breakpoints.down('680')]: {
      maxWidth: '62px',
      maxHeight: '62px'
    }
  },
  aside: {
    width: '100%',
    alignItems: 'center',
  },
  desc: {
    // maxWidth: '700px',
    // width: '100%',
    // padding: '32px 39px 45px 46px',
    // border: '1px solid #BDBDBD',
    alignItems: 'center',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    justifyContent: "center",
    letterSpacing: '0.15px',
    color: '#000000',
    [theme.breakpoints.down('680')]: {
      // maxWidth: '370px',
      padding: "20px",
    }
  },
  descTitle2: {
    fontWeight: '450',
    fontSize: '13.5px',
  },
  descTitle: {
    marginBottom: '25px',
    fontWeight: '400',
    fontSize: '25px',
    // float: "left",
    // alignSelf: "left",
    // justifyContent: "left",
    lineHeight: '24px'
  },
  grid_style: {
    // alignItems: 'center',
    // display: 'flex',
    // justifyContent: 'center'
  },
  priceItemName: {
    fontWeight: '500',
    fontSize: '16.5px',
    display: 'flex',
    // justifyContent: 'flex-end'
  },
  priceItemName_rates: {
    fontWeight: '500',
    fontSize: '16.5px',
    display: 'flex',
    color: '#FF3D00'
  },
  priceItemName_total: {
    fontWeight: '500',
    fontSize: '18.5px',
    display: 'flex',
    color: 'blue',
    paddingTop: '10px',
    paddingBottom: '10px'
    // [theme.breakpoints.down('768')]: {
    //   justifyContent: 'center'
    // }
  },
  priceItem: {
    marginBottom: '22px',
    width: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  price_div: {
    textAlign: 'center',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  priceItem__: {
    marginBottom: '22px',
    width: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  priceItemPromo: {
    marginTop: '20px',
    color: 'blue',
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-around'
  },
  priceItemTotal: {
    marginTop: '40px',
    fontWeight: '600',
    color: 'blue',
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-around'
  },
  wrapBlocks: {
    marginBottom: '40px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('1030')]: {
      flexDirection: 'column',
      maxWidth: '440px',
      margin: '0 auto',
      alignItems: 'center',
      marginBottom: '40px'
    }
  },
  notesWrap: {
    width: '100%',
    marginTop: '20px',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  mobCont: {
    alignSelf: "center",
    alignItems: 'center',
    width: '60%', 
    justifyContent: 'center',
    [theme.breakpoints.down('768')]: {
      width: '100%', 
    }
  },

  row_info: { 
    flex: 1,  
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
}));

function PetProviderInfo(props) {
  const classes = useStyles();
  
  const [service, setService] = React.useState('');
  const [recurringValue, setRecurring] = React.useState(null);
  // const [recurringDayName, setRecurringDayName] = React.useState(null);
  const [recurringDay, setRecurringDay] = React.useState(null);
  const [recurringInterval, setBookInterval] = React.useState(null);

  const [icon, setIcon] = React.useState('');
  const [book_data_present, setBook_data] = React.useState(true);

  const [rate, setRate] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [pets_length, setPetsLength] = React.useState('');
  const [pets_booking, setPets] = React.useState(null);


  const [start_time, setStart_time] = React.useState('');
  const [end_time, setEnd_time] = React.useState('');

  const [start_date, setStart_date] = React.useState('');
  const [end_date, setEnd_date] = React.useState('');

  const [diff_hours, setDiff_hours] = React.useState('');
  const [diff_min, setDiff_min] = React.useState('');
  const [number_days, setNumberDays] = React.useState(0);
  const [number_weeks, setNumberWeeks] = React.useState(0);
  const [number_months, setNumberMonths] = React.useState(0);
  const [number_nights, setNumberNights] = React.useState(0);

  const [additional_holiday, setAdditional_holiday] = React.useState(0);
  const [additional_puppy, setAdditional_puppy] = React.useState(0);
  const [over10_rate, setOver10MilesRate] = React.useState(0);
  const [additional_dog, setAdditionalDog] = React.useState(0);

  const [Asmall_pets, setAsmall_pets] = React.useState(0);
  const [Amedium_pets, setAmedium_pets] = React.useState(0);
  const [Alarge_pets, setAlarge_pets] = React.useState(0);
  
  // Disabled because these variables are attached to a disabled feature.
  // eslint-disable-next-line no-unused-vars
  const [final_fare, setFinal_fare] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [homepetvet_fee, setHPV_fee] = React.useState(0);
  const [gross_total, setGross_total] = React.useState(0);
  const [processing_fee_, setProcessingFee] = React.useState(0);
  const [final_gross_total, setFinalGrossTotal] = React.useState(0);

  React.useEffect(() => {
    // console.log('Here');
    function diff(start, end) {
      start = start.split(':');
      end = end.split(':');
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);

      // console.log("Minutes: " + minutes)
      // console.log("Hours: " + hours)

      setDiff_min(Math.abs(minutes));
      setDiff_hours(Math.abs(hours));
      // return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
    }

    function diff_values(start, end, return_value) {
      start = start.split(':');
      end = end.split(':');
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);

      setDiff_min(Math.abs(minutes));
      setDiff_hours(Math.abs(hours));
      if (return_value === 'minutes') {
        return Math.abs(minutes);
      }
      if (return_value === 'hours') {
        return Math.abs(hours);
      }
    }

    function convertTo12Hour(oldFormatTime) {
        // console.log("oldFormatTime: " + oldFormatTime);
        var oldFormatTimeArray = oldFormatTime.split(':');

        var HH = parseInt(oldFormatTimeArray[0]);
        var min = oldFormatTimeArray[1];

        var AMPM = HH >= 12 ? 'PM' : 'AM';
        var hours;
        if (HH === 0) {
          hours = HH + 12;
        } else if (HH > 12) {
          hours = HH - 12;
        } else {
          hours = HH;
        }
        var newFormatTime = hours + ':' + min + ' ' + AMPM;
        return newFormatTime;
      }

    var service = JSON.parse(localStorage.getItem('service_object'));
    var bookingData = JSON.parse(localStorage.getItem('booking_object'));
    const booking_api_object = localStorage.getItem("booking_api_object");
    const parsedObject = JSON.parse(booking_api_object);
    // console.log("parsedObject?.price_breakdown")
    // console.log(parsedObject)
    // console.log(parsedObject?.price_breakdown)
    // var puppies_exist = localStorage.getItem('puppies_exist');
    // console.log(service);
    if (service !== null && service !== undefined && bookingData !== null && bookingData !== undefined) {
      
      let add_holiday_rate = parseFloat(0);
      let add_puppy_rate = parseFloat(0);
      let add_over10_rate = parseFloat(0);
      let additional_dog_ = parseFloat(0);

      let small_pets = parseFloat(0);
      let medium_pets = parseFloat(0);
      let large_pets = parseFloat(0);

      if (service.additional_rates.length > 0) {
          // additional rates
          service.additional_rates.filter((instance) => {
            const price_add = instance.price;
            const rate_type = instance.rate_type.name;
            // console.log(price_add);
            // console.log(rate_type);

              // -->  holiday rate
              var date_a = new Date().getDate();
              var month_a = new Date().getMonth() + 1;
              var year_a = new Date().getFullYear();

              var current_object = year_a + '-' + month_a + '-' + date_a;

              if (rate_type === 'Holiday Rate') {
                const date = new Date(current_object)
                if(holidays.isHoliday(date) === true){
                  setAdditional_holiday(price_add);
                  add_holiday_rate = parseFloat(price_add);
                }
              }
          });


          // Other rates
          const pets_arr = JSON.parse(localStorage.getItem('pets_array'));
          console.log(pets_arr)
          const selected_pet = props.pets?.results?.filter((pet) => pet.id === pets_arr[0])
          if(selected_pet?.length >= 1){ 
            if(pets_arr?.length >= 2 && service.service.name === "Grooming"){
                const check_size_billing  = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                const check_size_billing2 = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                const check_size_billing3 = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs") 

                if(check_size_billing.length !== 0 || check_size_billing2.length !== 0 || check_size_billing3.length !== 0){
                  const pets_data_array = []
                  // console.log(pets_arr)
                  for (let inde = 0; inde < pets_arr?.length; inde++) {
                    // console.log(pets_arr[inde])
                    const get_pet = props.pets?.results?.filter((pet) => pet.id === pets_arr[inde])
                    pets_data_array.push(get_pet)
                  }
                  for (let inde2 = 0; inde2 < pets_data_array?.length; inde2++) {
                      const pet_size = (parseInt(selected_pet[0]?.size))
                      const pet_size_ = (parseInt(pets_data_array[inde2][0]?.size))
                      if(pet_size_ <= 15){
                        const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                        if(arate_type?.length >= 1){
                          small_pets = parseFloat(arate_type[0]?.price);
                          setAsmall_pets(arate_type[0]?.price)
                        }
                      }
                      if(pet_size_ >= 16 && pet_size_ <= 50){
                        const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                        if(arate_type?.length >= 1){
                          medium_pets = parseFloat(arate_type[0]?.price);
                          setAmedium_pets(arate_type[0]?.price)
                        }
                      }
                      if(pet_size_ > 50){
                        const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
                        if(arate_type?.length >= 1){
                          large_pets = parseFloat(arate_type[0]?.price);
                          setAlarge_pets(arate_type[0]?.price)
                        }
                      }
                  }
                } 

            }else{
              const pet_size = (parseInt(selected_pet[0]?.size))
              if(pet_size <= 15){
                const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Small pet (up to 15) lbs")
                if(arate_type?.length >= 1){
                  small_pets = parseFloat(arate_type[0]?.price);
                  setAsmall_pets(arate_type[0]?.price)
                }
              }
              if(pet_size >= 16 && pet_size <= 50){
                const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Medium pet (15 - 50) lbs")
                if(arate_type?.length >= 1){
                  medium_pets = parseFloat(arate_type[0]?.price);
                  setAmedium_pets(arate_type[0]?.price)
                }
              }
              if(pet_size > 50){
                const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Large pet (Over 50) lbs")
                if(arate_type?.length >= 1){
                  large_pets = parseFloat(arate_type[0]?.price);
                  setAlarge_pets(arate_type[0]?.price)
                }
              }
            }
            

            // Puppy Rate based on (dog) pet's age
            const pet_age = (parseInt(selected_pet[0]?.age))
            if(pet_age <= 2){
              const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Puppy Rate")
              if(arate_type.length >= 1){
                add_puppy_rate = parseFloat(arate_type[0]?.price);
                setAdditional_puppy(arate_type[0]?.price);
              }
            }

            // Over Milage Rate 
            if(service.service.name === "Grooming" || 
               service.service.name === "Grooming (Big dog: over 50 lb)" || 
               service.service.name === "Grooming (medium dog: 15 to 50 lb)" ||
               service.service.name === "Grooming (Small dog: up to 15 lb)"
              ){
              let distance = 20
              if(distance > 10){
                const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Over 10 miles")
                if(arate_type.length >= 1){
                  add_over10_rate = parseFloat(arate_type[0]?.price);
                  setOver10MilesRate(arate_type[0]?.price);
                }
              }
            }

            // Additional pet 
            if(pets_arr?.length > 1 && service.service.name !== "Grooming"){
              const additional_pet_length = pets_arr?.length - 1
              console.log("additional_pet_length")
              console.log(additional_pet_length)
              const arate_type = service.additional_rates.filter((rate) => rate.rate_type.name === "Additional pet")
              if(arate_type.length >= 1){
                // const total_addition_dog = (arate_type[0]?.price * additional_pet_length).toFixed(2)
                const total_addition_dog = (arate_type[0]?.price).toFixed(2)
                console.log("total_addition_dog")
                console.log(total_addition_dog)
                additional_dog_ = parseFloat(total_addition_dog);
                setAdditionalDog(additional_dog_);
              }
            }


          }
      }

      setService(service.service.name);
      setIcon(service.service.icon !== null ? service.service.icon : null);
      setRate(service.rate.name);
      setPrice(service.price);
      setPetsLength(parsedObject?.pets?.length);
      setPetsLength(parsedObject?.pets?.length);
      setPets(parsedObject?.pets);
      setNumberNights(parsedObject?.duration?.nights)
      setNumberDays(parsedObject?.duration?.days)
      setRecurring(parsedObject?.recurring)
      setRecurringDay(parsedObject?.recurring_day)
      setBookInterval(parsedObject?.interval)


      function addTrailzero(time) {
        const [hours, minutes] = time.split(':');
        const formattedHours = hours.length === 1 ? '0' + hours : hours;
        const formattedMinutes = minutes.length === 1 ? '0' + minutes : minutes;
        return `${formattedHours}:${formattedMinutes}`;
      }

      var obj = {
        'Date/Time': `${bookingData.start_date} ${addTrailzero(bookingData.start_time)}`
      };

      const test_date1 = moment(obj['Date/Time'], "YYYY-MM-DD HH:mm").format('YYYY-MM-DD');
      const test_time1 = moment(obj['Date/Time'], "YYYY-MM-DD HH:mm").format('HH:mm:ss');

      var obj2 = {
        'Date/Time': `${bookingData.end_date} ${addTrailzero(bookingData.end_time)}`
      };

      const test_date2 = moment(obj2['Date/Time'], "YYYY-MM-DD HH:mm").format('YYYY-MM-DD');
      const test_time2 = moment(obj2['Date/Time'], "YYYY-MM-DD HH:mm").format('HH:mm:ss');

      setStart_time(convertTo12Hour(test_time1.toString().substring(0, 5)));
      setEnd_time(convertTo12Hour(test_time2.toString().substring(0, 5)));

      setStart_date(test_date1);
      // if(parsedObject?.recurring){
      //   const dayName = moment(bookingData.start_date, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('dddd');
      //   setRecurringDayName(dayName)
      // }
      
      setEnd_date(test_date2);

      const start = bookingData.start_time;
      const end = bookingData.end_time;
      diff(start, end);

      // Fares Calculating algorithnm
      // console.log("parsedObject?.price_breakdown")
      // console.log(obj)
      
      setProcessingFee((parsedObject?.price_breakdown.processing_fee).toFixed(2))
      // Additional Rates
      // setFinal_fare((add_holiday_rate + add_puppy_rate + small_pets + medium_pets + large_pets + add_over10_rate + additional_dog_).toFixed(2))
      setFinal_fare((parsedObject?.price_breakdown.additional_rates_total > 0 ? parsedObject?.price_breakdown.additional_rates_total.toFixed(2) : 0))
      // Services total
      setGross_total((parsedObject?.price_breakdown?.services_total).toFixed(2))
      setFinalGrossTotal((parsedObject?.price_breakdown?.total_cost).toFixed(2))

    } else {
      setBook_data(false);
    }
    // eslint-disable-next-line
  }, []);




  // const redirect_signin = (e) => {
  //   window.location.href = '/login';
  // };

  if (!props.auth.token) {
    console.log('User is not authenticated');
    return (
      <Fragment>
        <Redirect />
      </Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <div>{props.auth.isAuthenticated && <PrimarySearchAppBar />}</div>
      <div>{!props.auth.token ? <GuestNavBar /> : null}</div>
      {props?.details?.id ? (
        <Container maxWidth="md" className={classes.wizardContainer}>
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.constant}>
              <div className={classes.services}>
                {/*<div className={classes.title}>Price Breakdown</div>*/}
                {book_data_present === false ? (
                  <div className={classes.pageTitle}>
                    A summary of the charges for the service(s).
                  </div>
                ) : (
                  <div className={classes.aside}>
                    <div className={classes.desc}>

                      <div 
                        style={{ 
                          margin: "0px", 
                          display: 'flex', 
                          flexDirection: "column", 
                          // float: "center", 
                          // alignItems: "center" 
                        }}>
                        <div 
                          style={{ 
                            marginTop: "15px",
                            alignSelf: "center" 
                          }}><img alt="logo" src={logo} style={{ display: 'block' }} />
                        </div>
                        <span className={classes.descTitle2}>Professional Choice in Pet Care</span> 
                        <br/>
                        <span className={classes.descTitle}>Price breakdown</span>

                        <div container className={classes.mobCont}>
                          
                           <div className={classes.row_info}>
                             <span className={classes.priceItemName}>Start Date: </span> 
                             <div className={classes.priceItemName}><span>{`${moment(start_date, 'YYYY-MM-DD').toDate().toString().substring(0, 15)}`}</span></div> 
                           </div>

                           <div className={classes.row_info}>
                             <span className={classes.priceItemName}>End Date: </span> 
                            <div className={classes.priceItemName}><span>{`${moment(end_date, 'YYYY-MM-DD').toDate().toString().substring(0, 15)}`}</span></div> 
                           </div>

                           <div className={classes.row_info}>
                             <span className={classes.priceItemName}>Time: </span> 
                             <div className={classes.priceItemName}>{start_time} - {end_time}</div> 
                           </div>

                           {
                            service !== "Overnight" ?
                              <div className={classes.row_info}>
                               {diff_hours > 0 || diff_min > 0 ? <span className={classes.priceItemName}>Duration:  </span> : null}
                               {diff_hours > 0 || diff_min > 0 ? <div className={classes.priceItemName}>
                                <span>
                                  {diff_hours > 0 ? <span style={{ marginRight: '5px'}} >{`${diff_hours} hr(s)`}</span> : null}
                                </span>
                                <span>
                                  {diff_hours > 0 && diff_min > 0 ? <span style={{ marginRight: '5px'}}>{`and`}</span> : null}
                                </span>
                                <span>
                                  {diff_min > 0 ? <span>{`${diff_min} mins.`}</span> : null}
                                </span>
                               </div> :null}
                             </div>: null
                           }

                           {number_days > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName}>Day(s): </span> 
                             <div className={classes.priceItemName}>{number_days}</div> 
                           </div>: null}

                           {number_weeks > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName}>Week(s): </span> 
                             <div className={classes.priceItemName}>{number_weeks}</div> 
                           </div>: null}

                           {number_months > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName}>Month(s): </span> 
                             <div className={classes.priceItemName}>{number_months}</div>
                           </div>: null}

                           {(number_nights > 0 && service === "Overnight") ? <div className={classes.row_info}>
                             <span className={classes.priceItemName}>Night(s): </span> 
                             <div className={classes.priceItemName}>{number_nights}</div> 
                           </div>: null}

                           {pets_booking !== null ? 
                            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                             {pets_booking?.map((pets_book) => (
                                  <div className={classes.row_info}>
                                     <span className={classes.priceItemName}>{pets_book?.name}: {" "} {pets_book?.age}Yrs: </span> 
                                     <div className={classes.priceItemName}>{pets_book?.size} lbs</div> 
                                   </div>
                              )).reverse()}
                            </div>
                            : null}

                           <div className={classes.row_info}>
                             <span className={classes.priceItemName}>Service[s): </span>  
                             <div className={classes.priceItemName}>
                              {icon !== null ? <img alt="logo" src={icon} height="30px" width="30px" />:null}
                              <span style={{ 
                                marginTop: "5px",
                                marginLeft: icon !== null ? '5px' : '0px', 
                                alignItems: 'flex-start',
                                selfAlign: 'left',
                                alignSelf: 'left',
                                width: 'auto' 
                              }}>{service}
                              </span>
                            </div>
                           </div>
                           
                           <div className={classes.row_info}>
                             <span style={{ marginBottom: '20px' }} className={classes.priceItemName}>Base Rate: </span> 
                             <div  style={{ marginBottom: '20px' }} className={classes.priceItemName}>${price}.00 / {rate}</div> 
                           </div>

                           <div className={classes.row_info}>
                             <span style={{ marginBottom: '5px' }} className={classes.priceItemName}>Recurring: </span> 
                             <div  style={{ marginBottom: '5px' }} className={classes.priceItemName}>{recurringValue ? "Yes": "No"}</div> 
                           </div>
                           {recurringDay !== null ? <div className={classes.row_info}>
                             <span style={{ marginBottom: '5px' }} className={classes.priceItemName}>Recurring Day: </span> 
                             <div  style={{ marginBottom: '5px' }} className={classes.priceItemName}>{recurringDay}</div> 
                           </div>: null}
                           <div>
                             {
                               recurringValue ? 
                               <>{recurringInterval !== null ? <div className={classes.row_info}>
                                 <span style={{ marginBottom: '20px' }} className={classes.priceItemName}>Recurring Interval: </span> 
                                 <div  style={{ marginBottom: '20px' }} className={classes.priceItemName}>{recurringInterval}</div> 
                               </div>: null}</>: null
                              }
                           </div>
                           

                           
                           {additional_holiday > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName_rates}>Holiday Rate </span>  
                             <div className={classes.priceItemName_rates}>${additional_holiday}</div> 
                           </div>: null}

                           {additional_puppy > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName_rates}>Puppy Rate </span>  
                             <div className={classes.priceItemName_rates}>${additional_puppy}</div> 
                           </div>: null}

                           {over10_rate > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName_rates}>Over 10 miles </span> 
                              <div className={classes.priceItemName_rates}>${over10_rate}</div> 
                           </div>: null}

                           {additional_dog > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName_rates}>Additional pet </span> 
                              <div className={classes.priceItemName_rates}>${additional_dog}</div> 
                           </div>: null}

                           {Asmall_pets > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName_rates}>Small pet (up to 15) lbs</span> 
                              <div className={classes.priceItemName_rates}>${Asmall_pets}</div> 
                           </div>: null}

                           {Amedium_pets > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName_rates}>Medium pet (15 - 50) lbs</span> 
                              <div className={classes.priceItemName_rates}>${Amedium_pets}</div> 
                           </div>: null}

                           {Alarge_pets > 0 ? <div className={classes.row_info}>
                             <span className={classes.priceItemName_rates}>Large pet (Over 50) lbs</span> 
                              <div className={classes.priceItemName_rates}>${Alarge_pets}</div> 
                           </div>: null}


                           {final_fare > 0 ? <div className={classes.row_info}>
                             <span style={{ marginTop: "25px" }} className={classes.priceItemName_rates}>Additional Rates Sub Total</span> 
                              <div style={{ marginTop: "25px" }} className={classes.priceItemName_rates}>${final_fare}</div> 
                           </div>: null}

                           {/*gross_total > 0 ? <div className={classes.row_info}>
                             <span style={{ marginBottom: "25px" }} className={classes.priceItemName_rates}>Services Sub Total</span> 
                              <div style={{ marginBottom: "25px" }} className={classes.priceItemName_rates}>${gross_total}</div> 
                           </div>: null*/}


                           {processing_fee_ !== null ? <div className={classes.row_info}>
                             <span style={{ marginTop: "25px", marginBottom: "5px" }} className={classes.priceItemName_rates}>Processing Fee </span>
                             <div style={{ marginTop: "25px",  marginBottom: "5px" }} className={classes.priceItemName_rates}>${processing_fee_}</div> 
                           </div>: null}

                           {/*homepetvet_fee > 0 ? <div className={classes.row_info}>
                             <span style={{ marginBottom: '30px' }} className={classes.priceItemName_rates}>Plartform Fee </span>
                              <div style={{ marginBottom: '30px' }} className={classes.priceItemName_rates}>${homepetvet_fee}</div> 
                           </div>: null*/}

                           <div className={classes.line}/>
                              <div className={classes.row_info}>
                               <div className={classes.priceItemName_total}>TOTAL</div>
                               <div className={classes.priceItemName_total}>
                                 ${final_gross_total}
                               </div> 
                              </div>
                           <div style={{ marginBottom: '50px' }} className={classes.line}/>

                          </div>
                        </div>
                    </div>
                  </div>
                )}
                
              </div>
            </div>
        </Paper>
        <div className={classes.notesWrap}>
          <div className={classes.titleBlock}>More Info: You will be billed once your booking is confirmed. Thank you for using Homepetvet.</div>
        </div>
        </Container>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '73px',
            background: '#fff'
          }}>
          <div
            style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img
              style={{
                position: 'absolute',
                marginBottom: '41px',
                marginRight: '31px'
              }}
              src={Footprint}
              alt="logo"
            />
            <img src={Spinner} alt="logo" height="100px" width="100px" />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pets: state.pets.pets,
  form: state.forms,
  booking: state.booking,
  messages: state.messages,
  availability: state.forms.availability_data,
  details: state.results.petproviderdetails
});

export default connect(mapStateToProps, {
  get_availability_data,
  clear_aval,
  getproviderdetails,
  loadUser,
  getPetData,
  clear_error,
  createMessage,
  create_booking
})(PetProviderInfo);



 // Your money will not be dispersed to the pet provider before the service is
 // done.

  // You will only be billed after the service has been completed. If you have multiple appointments; you 
  // will only be billed after completing each service although your receipt shows the total amount for multiple bookings